define('life-light-web/components/clr-textbox', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend(_ember['default'].ViewTargetActionSupport, {
		tagName: 'input',
		classNames: ['clr-input'],
		attributeBindings: ['name', 'placeholder', 'autocomplete'],
		didInsertElement: function didInsertElement() {
			var self = this;
			var span = $('<span class="clr glyphicon glyphicon-remove-circle"/>').on('click', function () {
				self.$().val('').focus();
				$(this).hide();
			});
			self.$().after(span);
			self.$().one('focus', function () {
				span.position({
					my: 'right center',
					at: 'right-5 center',
					of: self.$()
				}).hide().css('opacity', 0.64);
				self.$().css('padding-right', span.width() + 15 + 'px');
			});

			this.clr = span;
		},

		keyUp: function keyUp(e) {
			switch (e.keyCode) {
				case '27':
					this.$().val('');
					break;
				default:
					// Ember.Logger.info(e.keyCode);
					$(this.clr).toggle(Boolean(this.$().val()));
			}
		},

		//	attributeBindings: ["href", "download"],
		actions: {
			_click: function _click() /*event*/{
				var actionMethod = this.get("onAction");
				if (actionMethod) {
					actionMethod(this.get("key"));
				}
				this.triggerAction();
			}
		}
	});
});