define("life-light-web/templates/articles/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "life-light-web/templates/articles/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "articles-list-body");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "class", "page-title");
        dom.setAttribute(el2, "style", "display:block");
        var el3 = dom.createTextNode("LifeLight Center Articles");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "well well-sm well-description");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("The articles listed below are written by LifeLight's Rose Carey beginning in 2013 to present and published in the ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "#");
        dom.setAttribute(el4, "class", "btn btn-shopper btn-xs");
        var el5 = dom.createTextNode("Monadnock Shopper News");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" and the ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "#");
        dom.setAttribute(el4, "class", "btn btn-sentinel btn-xs");
        var el5 = dom.createTextNode("Keene Sentinel");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(". Through these articles, Rose imparts her knowledge of the human energy system and how it relates to your health and healing. Each article is categorized and tagged by the author to help you meet your search interests. You will also find ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "#");
        dom.setAttribute(el4, "class", "btn btn-other btn-xs");
        var el5 = dom.createTextNode("Other Articles");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" of interest.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0, 5, 5);
        morphs[1] = dom.createMorphAt(element0, 7, 7);
        return morphs;
      },
      statements: [["inline", "articles-index", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [6, 24], [6, 29]]]]], [], []], "articles", ["subexpr", "@mut", [["get", "articles", ["loc", [null, [6, 39], [6, 47]]]]], [], []], "selectedTag", ["subexpr", "@mut", [["get", "selectedTag", ["loc", [null, [6, 60], [6, 71]]]]], [], []], "tagList", ["subexpr", "@mut", [["get", "tagList", ["loc", [null, [6, 80], [6, 87]]]]], [], []], "onTagDelete", ["subexpr", "action", ["deleteTag"], [], ["loc", [null, [6, 100], [6, 120]]]], "onTagUpdate", ["subexpr", "action", ["updateTag"], [], ["loc", [null, [6, 133], [6, 153]]]]], ["loc", [null, [6, 1], [6, 155]]]], ["content", "outlet", ["loc", [null, [8, 1], [8, 11]]]]],
      locals: [],
      templates: []
    };
  })());
});