define('life-light-web/authorizers/application', ['exports', 'ember', 'ember-simple-auth/authorizers/base'], function (exports, _ember, _emberSimpleAuthAuthorizersBase) {
  exports['default'] = _emberSimpleAuthAuthorizersBase['default'].extend({
    name: 'authorization',
    before: 'ember-simple-auth',
    // implement heyar!
    authorize: function authorize(jqXHR /*, requestOptions*/) {
      if (this.get('session.isAuthenticated') && !_ember['default'].isEmpty(this.get('session.token'))) {
        jqXHR.setRequestHeader('token', this.get('session.token'));
      }
    },

    initialize: function initialize(container) {
      container.register('authorizer:custom', this);
    }
  });

  // var CustomAuthorizer = Base.extend({
  //   // implement heyar!
  //   authorize: function(jqXHR /*, requestOptions*/) {
  //     if (this.get('session.isAuthenticated') && !Ember.isEmpty(this.get('session.token'))) {
  //       jqXHR.setRequestHeader('token', this.get('session.token'));
  //     }
  //   }
  // });

  // export var initialize = function(container) {
  //   container.register('authorizer:custom', CustomAuthorizer);
  // };

  // export default {
  //   name: 'authorization',
  //   before: 'ember-simple-auth',
  //   initialize: initialize
  // };
});