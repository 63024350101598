define('life-light-web/components/periodical-component', ['exports', 'ember', 'moment', 'ember-resize/mixins/resize-aware', 'life-light-web/mixins/scrolling'], function (exports, _ember, _moment, _emberResizeMixinsResizeAware, _lifeLightWebMixinsScrolling) {
    exports['default'] = _ember['default'].Component.extend(_lifeLightWebMixinsScrolling['default'], {
        resizeService: _ember['default'].inject.service(),

        resizeCounter: 0,
        isEditing: false,
        isEditingObserver: (function () {
            if (!!!this.get("isEditing")) {
                this.$().closest('body').scrollTop(0);
            }
        }).observes("isEditing"),

        useCustomFooter: false,
        useCustomFooterObserver: (function () {
            this.set("useCustomFooter", this.get("model.useCustomFooter") ? true : false);
        }).observes("model"),

        fontSize: 16,

        /*    isBookmarked: function() {
                return this.get("bookmarkId") === parseInt(this.get("model.id"));
            }.property("bookmarkId", "model.id"),
        */
        bookmarkObserver: function bookmarkObserver() {
            _ember['default'].Logger.info('bookmarkObserver');
        },

        isShopperNews: (function () {
            return this.get("model.type") === "shopperNews";
        }).property("model.type"),

        formatted_publish_date: (function () {
            return _moment['default'].parse(Date(this.get("model.publishDate"))).format("LL");
        }).property("model.publishDate"),

        categories: (function () {
            var self = this;
            var categoryList = self.get("articles.categoryList");
            var categories = categoryList.map(function (category) {
                var theCategories = self.get("model.categories");
                var checked = theCategories ? theCategories.indexOf(category) !== -1 : false;
                return { "name": category, "checked": checked };
            });

            return categories;
        }).property("model.categories", "articles.categoryList"),

        checkedTagList: (function () {
            var self = this;
            var tagList = self.get("articles.tagList");
            var tags = tagList.map(function (tag) {
                var articleTags = self.get("model.tags");
                var checked = articleTags.findBy("text", tag.get("text")) ? true : false;
                return { "id": tag.get("id"), "text": tag.get("text"), "checked": checked };
            });

            return tags;
        }).property("model.tags", "articles.tagList", "articles.tagList.[]"),

        showRatingData: false,

        actions: {
            tagChecked: function tagChecked(tags) {
                this.set("model.tags", tags);
            },

            toggleEditing: function toggleEditing() {
                this.toggleProperty("isEditing");
            },

            update: function update() {
                var self = this;
                self.set("isEditing", false);
            },

            cancel: function cancel() {
                if (this.get("model.id")) {
                    this.get("model").rollbackAttributes();
                    this.set("isEditing", false);
                } else {
                    this.get("model").deleteRecord();
                    this.transitionTo("articles");
                }
            },

            navigate: function navigate(direction) {
                this.get("articles").send("navigate", direction);
            },

            searchChanged: function searchChanged(searchTerm) {
                _ember['default'].Logger.info("Periodical search is: %@".fmt(searchTerm));
                this.set("searchTerm", searchTerm ? searchTerm : null);
                this.get("articles").send("searchChanged", searchTerm);
            },

            fontSizeChanged: function fontSizeChanged(fontSize) {
                this.set("fontSize", fontSize);
                this.get("onFontSizeChanged")(fontSize);
                _ember['default'].Logger.info('New font size: ' + this.get("fontSize"));
                return true;
            },

            bookmarked: function bookmarked(marked) {
                _ember['default'].Logger.info('Bookmarked in Periodical with ' + marked);
                var bookmarkId = marked ? this.get("model.id") : undefined;
                this.set("bookmarkId", bookmarkId);
                this.get("articles").send("bookmarked", bookmarkId);
            },

            articleRated: function articleRated(value) {
                _ember['default'].Logger.info("%@ has been rated %@ stars.".fmt(this.get("model.title"), value));
                // Need to write this cookie
                // this.set("rated", value);
                var ratedArray = this.get("ratedArray");
                ratedArray[this.get("model.id")] = value;
                var jsonString = JSON.stringify(ratedArray);
                var encodedString = btoa(jsonString);
                this.set("customerRatings", encodedString);
                // this.set("ratedArray", ratedArray);
                // this.get("ratedArray")[this.get("model.id")] = value;
                this.incrementProperty("model.ratingCount");
                this.incrementProperty("model.ratingTotal", value);
                this.get("model").save();
            },

            testSearchManager: function testSearchManager() {
                this.get("searchManager").testSearchTerm();
            },

            changeType: function changeType(type) {
                this.set("model.type", type);
            },

            changeSubType: function changeSubType(type) {
                this.set("model.subType", type);
            },

            categoryChecked: function categoryChecked(categories) {
                this.set("model.categories", categories.join(","));
            },

            createNew: function createNew() {
                var article = this.store.createRecord("periodical");
                article.type = "shopperNews";
                article.subType = "shopperNews";
                // this.transitionToRoute("periodical", article);
                this.set("model", article);
                this.toggleProperty("isEditing");
            },

            remove: function remove() {
                this.get("model").destroyRecord();
                this.transitionToRoute("articles");
            },

            addNewTag: function addNewTag(tagName) {
                this.get("addNewTag")(tagName);
                // this.get("articles").send("addNewTag", tagName);
            }
        },

        resizeHandler: (function () /*width, height, event*/{
            this.get("vertical-text").titleObserver();
        }).on("didDebounceResize"),

        scrolled: function scrolled() {
            if (!!!this.get("isEditing")) {
                this.get("setVerticalTitleOpacity")(this);
            }
        },

        verticalScrollBreakpoints: {
            ABOVE_TITLE: 0,
            BELOW_TITLE: 1
        },

        setVerticalTitleOpacity: function setVerticalTitleOpacity(context) {
            var scrollTop = context.$(window).scrollTop();
            var vtitle = context.$(".title-vertical");
            var titleTop = context.$("h3.article-title").offset().top;
            // Ember.Logger.info("ScrollTop: " + scrollTop + ", TitleTop: " + titleTop + ", Vertical Title height: " + vtitle.width());
            var opacity;
            if (scrollTop > titleTop + vtitle.width()) {
                opacity = 1.0;
            } else if (scrollTop < titleTop) {
                opacity = 0.0;
                context.set("verticalBreakpoint", context.verticalScrollBreakpoints.ABOVE_TITLE);
            } else {
                opacity = (scrollTop - titleTop) / vtitle.width();
                context.set("verticalBreakpoint", context.verticalScrollBreakpoints.BELOW_TITLE);
            }

            vtitle.css("opacity", opacity);
            // Ember.Logger.info("Opacity: " + opacity);
        },

        verticalBreakpointObserver: (function () {
            _ember['default'].$(".back-to-top").toggle(this.get("verticalBreakpoint") === this.verticalScrollBreakpoints.BELOW_TITLE);
        }).observes("verticalBreakpoint"),

        publishSource: (function () {
            var source = this.get("model.type") === "shopperNews" ? this.get("model.subType") === "sentinel" ? "Keene Sentinel" : "Monadnock Shopper News" : null;
            return source;
            // }.property("model.type", "model.subType"),
        }).property("model"),

        customerRatings: "",
        ratedArray: (function () {
            var customerRatings = this.get("customerRatings");
            if (customerRatings) {
                customerRatings = atob(customerRatings);
                var ratedArray = JSON.parse(customerRatings);
                return ratedArray ? ratedArray : {};
            } else {
                return {};
            }
        }).property("customerRatings"),

        rating: (function () {
            var total = this.get("model.ratingTotal");
            if (total) {
                return parseFloat(total / this.get("model.ratingCount"));
            } else {
                return null;
            }
        }).property("model.ratingTotal", "model.ratingCount"),

        modelObserver: (function () {
            var ratedArray = this.get("ratedArray");
            var id = this.get("model.id");
            var rating = ratedArray[id];
            this.set("rated", rating);

            this.get("transformVerticalTitle")(this);

            _ember['default'].Logger.info("Model changed");
        }).observes("model"),

        transformVerticalTitle: function transformVerticalTitle(self) {
            return;
            _ember['default'].run.once(function () {
                var title = self.$(".title-vertical");
                var width = title.css("width");

                if (parseFloat(width) + 30 > window.innerHeight) {
                    title.css("font-size", parseFloat(title.css("font-size")) - 4 + "px");
                }
                // Ember.Logger.info("Vertical title width: " + width + " for " + self.get("model.title"));
                //** var transformStr = "translateY(" + width + ") translateX(-38px) rotate(270deg)";
                var transformStr = "translateX(-38px) translateY(" + width + ") rotate(270deg)";
                //** self.$(".title-vertical").css({transform: transformStr});
                title.css({ transform: transformStr });
            });
        },

        searchTermObserver: (function () {
            this.get("searchAndHighlight")(this);
        }).observes("searchTerm", "model.body"),

        searchTarget: "body",
        searchAndHighlight: function searchAndHighlight(context) {
            var self = context;
            var srcStr = self.get("clearHighlights")(self);
            if (!!!srcStr) {
                return;
            }

            var term = self.get("searchTerm");
            if (term && term.length > 2 && self.get("searchTarget") === "body") {
                term = term.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
                var pattern = new RegExp("(" + term + ")", "gi");

                srcStr = srcStr.replace(pattern, "<mark>$1</mark>");
                srcStr = srcStr.replace(/(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, "$1</mark>$2<mark>$4");
            } else {
                self.$(".search-graph").empty().animate({ 'opacity': 0 });
            }

            self.set("model.body", srcStr);
        },

        clearHighlights: function clearHighlights(context) {
            var source = context.get("model.body");
            if (!!!source) {
                return null;
            }

            source = source.replace(/(<mark[^<>]*>|<\/mark>)/g, "");
            return source;
        },

        searchNotFound: (function () {
            var searchTerm = this.get("searchTerm");
            return searchTerm && searchTerm.length >= 3 && this.get("model.body").indexOf("<mark>") === -1;
        }).property("searchTerm", "model.body"),

        latestArticleId: _ember['default'].computed.alias("articles.latestArticle.id"),

        didInsertElement: function didInsertElement() {
            var self = this;
            this.set("resizeService.defaultHeightSensitive", true);
            this.get("resizeService").on("debouncedDidResize", function (event) {
                _ember['default'].Logger.info("Please log this!");
                self.incrementProperty("resizeCounter");
            });

            var scrollingOptions = { context: this, name: "lifelight-articles", debounce: 1 };
            this.bindScrolling(scrollingOptions);

            this.get("transformVerticalTitle")(this);

            /*        if (this.$(".article-body p").hasClass("skill")) {
                        // var firstSkillParagraph = this.$(".skill").first();
                        // firstSkillParagraph.addClass("first-skill-paragraph");
                        this.$(".skill:last").addClass("last-skill-paragraph");
                    }
            */

            var anchorName = this.get("anchor");
            switch (anchorName) {
                case "skill":
                    if (this.$(".article-body p").hasClass("skill")) {
                        var firstSkillParagraph = this.$(".skill").first();
                        this.$().closest("body").scrollTop(firstSkillParagraph.offset().top - 18);
                    }

                    break;
            }

            this.set("originalTags", this.get("model.tags").mapBy("id"));
        },

        markSkills: (function (args) {
            if (this.toggleProperty("firstRender")) {
                if (this.$(".article-body p").hasClass("skill")) {
                    this.$(".skill:last").addClass("last-skill-paragraph");
                }
            }
        }).on("didRender"),

        willDestroyElement: function willDestroyElement() {
            var model = this.get("model");
            if (this.get("model.id")) {
                model.rollbackAttributes();
            } else {
                model.destroyRecord();
            }

            this.unbindScrolling("lifelight-articles");
        }
    });
});