define("life-light-web/components/count-rotator", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		length: undefined,

		didInsertElement: function didInsertElement() {
			var children = this.$().children();
			this.set("length", children.hide().length);
			children.first().show();
		},

		transitionObserver: (function () {
			var modulus = this.get("transitionCount") % this.get("length");
			this.$().children().hide().eq(modulus).show();
		}).observes("transitionCount")
	});
});