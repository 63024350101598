define("life-light-web/components/testimonial-component", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		classNames: ["testimonial"],
		classNameBindings: ["isApproved:approved:not-approved"],

		actions: {
			approve: function approve(testimonial) {
				this.get("onApprove")(testimonial);
			},

			"delete": function _delete(testimonial) {
				this.get("onDelete")(testimonial);
			}
		},

		didInsertElement: function didInsertElement() {
			if (!this.isAuthenticated) {
				return;
			}
			var self = this;
			self.$().hover(function () {
				self.$().addClass("focus");
				self.$("button.btn-remove, button.btn-approve.btn-warning", self.$()).show(200);
			}, function () {
				self.$("button.btn-remove, button.btn-approve.btn-warning", self.$()).hide(200);
				self.$().removeClass("focus");
			});
		}
	});
});