define("life-light-web/components/article-search", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        tagName: "form",
        classNames: ["search-form"],
        classNameBindings: ["notFound"],

        minSearchLength: 3,
        previousSearchTerm: "",
        searchTerm: "",
        searchTermObserver: (function () {
            var searchTerm = this.get("searchTerm");
            var previousSearchTerm = this.get("previousSearchTerm");

            this.set("previousSearchTerm", searchTerm);

            var searchTermLength = searchTerm ? searchTerm.length : 0;
            var previousSearchTermLength = previousSearchTerm ? previousSearchTerm.length : 0;

            var minSearchLength = this.get("minSearchLength");
            if (searchTermLength < minSearchLength) {
                if (previousSearchTermLength < minSearchLength) {
                    return;
                }

                searchTerm = null;
            }

            if (this.get("onSearchTermChanged")) {
                this.get("onSearchTermChanged")(searchTerm);
            }
        }).observes("searchTerm"),

        didInsertElement: function didInsertElement() {
            var self = this;
            var body = self.$().closest("body");

            self.$(".search-input").focusin(function () {
                self.$(this).addClass("focus", "fast", "swing", function () {
                    self.$(".search-form .glyphicon.glyphicon-filter").show();
                }).keyup(function (e) {
                    switch (e.which) {
                        case 13:
                            event.preventDefault();
                            event.stopPropagation();

                            if (self.get("scrollToSearch")) {

                                _ember["default"].run.later(function () {
                                    self.$().closest("body").find(".search-graph div:nth(1)").trigger("click");
                                }, 0);
                            }
                            break;
                        case 27:
                            self.$(".search-input").val("");
                            self.set("searchTerm", null);
                            body.find('#blank_focus').focus();
                            body.find(".search-form .glyphicon.glyphicon-filter").hide();
                            // Context this refers to the input
                            self.$(this).removeClass("focus", "fast", "swing");
                            break;
                        default:
                    }
                }).keypress(function (event) {
                    if (event.keyCode === 13) {
                        return false;
                    }
                });

                body.on("click.search", ".search-input+span", function () /*e*/{
                    self.$(".search-input").val("");
                    self.set("searchTerm", null);
                    body.find('#blank_focus').focus();
                });

                body.on("click.search", function (e) {
                    if (!body.find(".search-form").has(_ember["default"].$(e.target)).length) {
                        body.find(".search-form .glyphicon.glyphicon-filter").hide();
                        self.$(".search-input").removeClass("focus", "fast", "swing");

                        body.off("click.search");
                    }
                });
            });

            if (self.get("searchTerm")) {
                self.$(".search-input").focus();
            }
        }
    });
});