define('life-light-web/routes/article', ['exports', 'ember', 'life-light-web/mixins/reset-scroll'], function (exports, _ember, _lifeLightWebMixinsResetScroll) {
	exports['default'] = _ember['default'].Route.extend(_lifeLightWebMixinsResetScroll['default'], {
		session: _ember['default'].inject.service(),

		setupController: function setupController(controller, model) {
			controller.set("model", model);
			controller.set("articles.articleType", model.get("type"));
			controller.send("persistIfLatest");
		},

		beforeModel: function beforeModel(transition) {
			if (!this.get('session.isAuthenticated')) {
				this.set('session.attemptedTransition', transition);
			}
		},

		afterModel: function afterModel(article /*, transition*/) {
			if (!!!article) {
				this.transitionTo("article", this.store.findAll("article").get("lastObject"));
			} /* else {
     var type = article.get("type");
     article.set("controller.articles.articleType", articleType);
     }*/
		},

		model: function model(params) {
			if (params.article_id) {
				return this.store.findRecord("article", params.article_id);
				// return this.store.queryRecord("article", { filter: { articleId : params.article_id }});
			} else {
					return this.store.findAll("article").get("firstObject");
				}
		}
	});
});