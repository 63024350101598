define("life-light-web/routes/articles/create", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Route.extend({
		setupController: function setupController(controller, model) {
			controller.set("model", model);
		},

		model: function model() {
			var article = this.store.createRecord("article", {
				type: "shopperNews",
				subType: "shopperNews"
			});

			return article;
		}

	});
});
/*	renderTemplate: function(controller, model) {
		var articleController = this.controllerFor("article");
		// this.render("components/article-editor", { outlet: "post" });
		this.render("articles/create", { controller: articleController });
		// this.render("components/article-editor");
	}*/