define('life-light-web/controllers/testimonials', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),

		approvedTestimonials: (function () {
			return this.get("model").filterBy("isApproved");
		}).property("model.@each.isApproved"),

		rejectedTestimonials: (function () {
			return this.get("model").filter(function (testimonial) {
				return testimonial.get("isApproved") === false;
			});
		}).property("model.@each.isApproved"),

		submittedTestimonials: (function () {
			return this.get("model").filter(function (testimonial) {
				return testimonial.get("isApproved") === null;
			});
		}).property("model.@each.isApproved"),

		sortedTestimonials: (function () {
			var sortedList = [];
			var list = this.get("model");
			var approved = list.filterBy("isApproved");
			var notApproved = list.filter(function (item) {
				return !item.get("isApproved");
			});
			sortedList.pushObjects(notApproved.toArray());
			sortedList.pushObjects(approved.toArray());

			return sortedList;
		}).property("session.isAuthenticated", "model.[]"),

		isInputtingNew: false,

		actions: {
			edit: function edit() {
				this.set("isInputtingNew", true);
			},

			cancel: function cancel() {
				this.set("isInputtingNew", false);
			},

			submit: function submit() {
				var testimonial = this.store.createRecord("testimonial", {
					testimonial: this.get("text"),
					signature: this.get("signature"),
					bonaFida: this.get("bonafida"),
					location: this.get("location")
				});

				testimonial.save();

				this.set("isInputtingNew", false);
			},

			approve: function approve(testimonial) {
				testimonial.toggleProperty("isApproved");
				testimonial.save();
			},

			'delete': function _delete(testimonial) {
				testimonial.destroyRecord();
			}
		}
	});
});