define("life-light-web/controllers/sitemap", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        application: _ember["default"].inject.controller(),
        articles: _ember["default"].inject.controller(),

        clothing: "sitemap",

        isSitemap: (function () {
            return this.get("application.currentRouteName") === "sitemap";
        }).property("application.currentRouteName"),

        shopperArticles: (function () {
            return null;
            return this.get("articles.model").filterBy("type", "shopperNews");
        }).property("articles.model"),

        otherArticles: (function () {
            return null;
            return this.get("articles.model").filterBy("type", "other");
        }).property("articles.model")
    });
});