define("life-light-web/services/search-manager", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Service.extend({
		searchTerm: undefined,

		searchTermObserver: function searchTermObserver() {
			_ember["default"].Logger.info("New searchTerm: %@".fmt(this.get("searchTerm")));
		},

		testSearchTerm: function testSearchTerm() {
			this.set("searchTerm", "panties");
		}
	});
});