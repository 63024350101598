define("life-light-web/components/slide-container", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		// attributeBindings: ["style"],
		classNames: ["slide-container"],

		didInsertElement: function didInsertElement() {
			var slider$ = this.$(".slider");
			var sliderWidth = parseInt(slider$.css("width"));

			this.$().css("width", 2 * sliderWidth);
			slider$.css({ top: 0, left: sliderWidth });
			slider$.draggable({
				axis: "x",
				cancel: "#textSizer",
				containment: "parent",
				cursor: "ew-resize",
				grid: [sliderWidth, 0]
			});
			// slider$.draggable( "option", "grid", [ sliderWidth, 20 ] );
		},

		oldDidInsertElement: function oldDidInsertElement() {
			var slider$ = this.$(".slider");
			var sliderWidth = parseInt(slider$.css("width"));

			this.$().css("width", 2 * sliderWidth);
			slider$.css({ top: 0, left: sliderWidth });
			slider$.css({ top: 0, left: 64 });
			slider$.draggable({
				axis: "x",
				cancel: "#textSizer",
				containment: "parent",
				cursor: "ew-resize",
				grid: [64, 0]
			});
			// slider$.draggable( "option", "grid", [ sliderWidth, 20 ] );
		},

		doubleClick: function doubleClick(e) {
			if (this.$(e.target).hasClass("slider")) {
				var slider = this.$(".slider");
				slider.toggleClass("open");
			}
		}
	});
});