define('life-light-web/controllers/articles', ['exports', 'ember', 'life-light-web/mixins/jq-cookie'], function (exports, _ember, _lifeLightWebMixinsJqCookie) {
	exports['default'] = _ember['default'].Controller.extend(_lifeLightWebMixinsJqCookie['default'], {
		application: _ember['default'].inject.controller(),
		article: _ember['default'].inject.controller(),
		session: _ember['default'].inject.service(),

		isAuthenticated: (function () {
			return this.get("session").isAuthenticated;
		}).property(),

		persistentProperties: ["bookmarkId:articleBookmark::365"],

		articleType: "shopperNews",
		searchTerm: null,
		isCategoriesVisible: true,
		showToc: false,
		bookmarkId: null,
		tagList: undefined,
		showTagsByDefault: true,

		showTagsByDefaultObserver: (function () {
			_ember['default'].Logger.info("Changing showTagsByDefault");
		}).observes('showTagsByDefault'),

		initialize: (function () {
			_ember['default'].Logger.info("Initialized Articles controller.");
		}).on("init"),

		did_load: function did_load() {
			_ember['default'].Logger.info("did_load Articles controller.");
		},

		articleList: (function () {
			var filteredList = this.get("model").filterBy("type", this.get("articleType"));
			return filteredList;
		}).property("model.[]", "articleType", "searchTerm"),

		searchArticleList: (function () {
			var filteredList = this.get("model").filterBy("type", this.get("articleType"));
			var searchTerm = this.get("searchTerm");
			if (searchTerm) {
				filteredList = filteredList.filter(function (article) {
					return article.get("body").indexOf(searchTerm) !== -1;
				});
			}

			return filteredList;
		}).property("model", "articleType", "searchTerm"),

		highlightSearchArticles: (function () {
			var searchTerm = this.get("searchTerm");
			if (!!!searchTerm || searchTerm.length < 4) {
				searchTerm = null;
			}

			this.get("model").forEach(function (article /*, index*/) {
				article.set("searching", searchTerm ? true : false);
				article.set("searched", searchTerm ? article.get("body").indexOf(searchTerm) !== -1 : false);
				article.set("inactive", searchTerm ? article.get("body").indexOf(searchTerm) === -1 : false);
			});
		}).observes("searchTerm"),

		latestArticle: (function () {
			return this.get("model.lastObject");
		}).property("model"),

		categoryListOld: (function () {
			var categories = this.get("articleList").map(function (article) {
				var categories = article.get("categories");
				return categories ? categories.split(",") : [];
			}).compact();
			categories = [].concat.apply([], categories);
			categories = categories.uniq();

			categories = this.store.findAll("category");
			categories = categories.get("content").mapBy("name");
			return categories;
		}).property("articleList"),

		actions: {
			articleIndexChange: function articleIndexChange(index) {
				this.transitionToRoute("article", index);
			},
			navigate: function navigate(direction) {
				var articles = this.get("articleList");
				var index = articles.indexOf(this.get("currentArticle"));
				var newIndex = index + parseInt(direction);
				var keepGoing = false;
				if (parseInt(direction) === 1) {
					keepGoing = newIndex >= 0;
				} else {
					keepGoing = newIndex < this.get("articleCount");
				}

				if (keepGoing) {
					var article = articles.objectAt(newIndex);
					this.transitionToRoute("article", article.get("id"));
				}
			},
			searchChanged: function searchChanged(searchTerm) {
				_ember['default'].Logger.info('Periodicals search is: ' + searchTerm);
				this.set("searchTerm", searchTerm);
			},
			bookmarked: function bookmarked(bookmarkId) {
				_ember['default'].Logger.info("Bookmarked in Articles");
				this.set("bookmarkId", bookmarkId);
			},
			categoriesChanged: function categoriesChanged(categoryCount) {
				this.set("article.isCategoriesVisible", categoryCount > 0);
			}
		},

		currentArticle: (function () {
			return this.get("article.model");
		}).property("article.model"),
		currentArticleId: _ember['default'].computed.alias("article.id"),

		isFirstArticle: (function () {
			return this.get("articleList").indexOf(this.get("currentArticle")) === 0;
		}).property("currentArticle", "articleList"),

		isLastArticle: (function () {
			return this.get("articleList").indexOf(this.get("currentArticle")) === this.get("articleCount") - 1;
		}).property("currentArticle", "articleList"),

		articleCount: (function () {
			return this.get("articleList.length");
		}).property("articleList"),

		isIndexPage: (function () {
			// Ember.Logger.info(this.get("application.currentPath"));
			return this.get("application.currentPath") === "articles.article.index";
		}).property("application.currentPath")
	});
});