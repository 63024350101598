/* jshint ignore:start */

define('life-light-web/config/environment', ['ember'], function(Ember) {
  var prefix = 'life-light-web';
/* jshint ignore:start */

try {
  var metaName = prefix + '/config/environment';
  var rawConfig = Ember['default'].$('meta[name="' + metaName + '"]').attr('content');
  var config = JSON.parse(unescape(rawConfig));

  return { 'default': config };
}
catch(err) {
  throw new Error('Could not read config from meta tag with name "' + metaName + '".');
}

/* jshint ignore:end */

});

if (!runningTests) {
  require("life-light-web/app")["default"].create({"API_HOST":"http://lifelightcenterllc.com","name":"life-light-web","version":"0.0.0+1375582f"});
}

/* jshint ignore:end */
