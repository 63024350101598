define('life-light-web/controllers/login2', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    init: function init() {
      var self = this;
      window.fbAsyncInit = function () {
        FB.init({
          appId: '1733156636917044',
          cookie: true, // enable cookies to allow the server to access
          // the session
          xfbml: true, // parse social plugins on this page
          version: 'v2.5' // use graph api version 2.5
        });

        // Now that we've initialized the JavaScript SDK, we call
        // FB.getLoginStatus().  This function gets the state of the
        // person visiting this page and can return one of three states to
        // the callback you provide.  They can be:
        //
        // 1. Logged into your app ('connected')
        // 2. Logged into Facebook, but not your app ('not_authorized')
        // 3. Not logged into Facebook and can't tell if they are logged into
        //    your app or not.
        //
        // These three cases are handled in the callback function.

        FB.getLoginStatus(function (response) {
          self.get('statusChangeCallback')(response, self);
        });
      };

      window.checkLoginState = function () {
        var self = this;
        FB.getLoginStatus(function (response) {
          self.get('statusChangeCallback')(response);
        });
      };

      // Load the SDK asynchronously
      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },

    status: undefined,

    actions: {
      // This function is called when someone finishes with the Login
      // Button.  See the onlogin handler attached to it in the sample
      // code below.
      checkLoginState: function checkLoginState() {
        var self = this;
        FB.getLoginStatus(function (response) {
          self.get('statusChangeCallback')(response);
        });
      }
    },

    // This is called with the results from from FB.getLoginStatus().
    statusChangeCallback: function statusChangeCallback(response, context) {
      if (this) {
        context = this;
      }
      console.log('statusChangeCallback');
      console.log(response);
      // The response object is returned with a status field that lets the
      // app know the current login status of the person.
      // Full docs on the response object can be found in the documentation
      // for FB.getLoginStatus().
      var status;
      if (response.status === 'connected') {
        // Logged into your app and Facebook.
        context.get('testAPI')(context);
      } else if (response.status === 'not_authorized') {
        // The person is logged into Facebook, but not your app.
        document.getElementById('status').innerHTML = 'Please log ' + 'into this app.';
        status = 'Please log into this app';
      } else {
        // The person is not logged into Facebook, so we're not sure if
        // they are logged into this app or not.
        document.getElementById('status').innerHTML = 'Please log ' + 'into Facebook.';
        status = 'Please log into this app';
      }

      context.set('status', status);
    },

    // Here we run a very simple test of the Graph API after login is
    // successful.  See statusChangeCallback() for when this call is made.
    testAPI: function testAPI(context) {
      console.log('Welcome!  Fetching your information.... ');
      FB.api('/me', function (response) {
        console.log('Successful login for: ' + response.name);
        document.getElementById('status').innerHTML = 'Thanks for logging in, ' + response.name + '!';

        context.set('status', 'Thanks for logging in, ' + response.name + '!');
      });
    }
  });
});