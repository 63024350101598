define("life-light-web/components/article-categories-menu", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        tagName: "nav",
        // classNames: ["navbar navbar-default navbar-articles visible-md visible-lg"],
        classNames: ["navbar navbar-default navbar-articles"],
        // classNameBindings: ["hasCategories::hide"],
        attributeBindings: ["role"],
        role: "navigation",
        /*    tagName: "ul",
            classNames: ["nav navbar-nav"],
        */
        articles: [],

        isVisible: (function () {
            var categories = this.get("categories");
            return categories ? categories.length > 0 : false;
        }).property("categories"),

        categoriesChanged: (function () {
            this.get("onCategoriesChanged")(this.get("categories.length"));
        }).observes("categories"),

        categories_array: (function () {
            var articles = this.get("articles");

            if (!!!articles) {
                return null;
            }

            var categories = articles.mapBy("categories").compact();
            categories = [].concat.apply([], categories);
            categories = categories.uniq();

            var cc = categories.map(function (category) {
                var articlesInCategory = articles.filter(function (article) {
                    var categories = article.get("categories");
                    return categories ? categories.contains(category) : null;
                }).map(function (article) {
                    return {
                        "title": article.get("title"),
                        "key": article.get("id")
                    };
                });
                return {
                    "title": category,
                    "articles": articlesInCategory
                };
            });

            return cc;
        }).property("articles"),

        categories: (function () {
            var articles = this.get("articles");

            if (!!!articles) {
                return null;
            }

            var categories = articles.map(function (item) {
                var categories = item.get("categories");
                return categories ? categories.split(",") : [];
            }).compact();
            categories = [].concat.apply([], categories);
            categories = categories.uniq().sort();

            var cc = categories.map(function (category) {
                var articlesInCategory = articles.filter(function (article) {
                    var categories = article.get("categories");
                    categories = categories ? categories.split(",") : [];
                    return categories ? categories.contains(category) : null;
                }).map(function (article) {
                    return {
                        "title": article.get("title"),
                        "key": article.get("id")
                    };
                });
                return {
                    "title": category,
                    "articles": articlesInCategory
                };
            });

            var orphans = articles.filter(function (article) {
                return _ember["default"].isEmpty(article.get("categories"));
            }).map(function (article) {
                return {
                    "title": article.get("title"),
                    "key": article.get("id")
                };
            });

            if (this.get("isAdmin") && orphans.length) {
                cc.pushObject({
                    "title": "Other",
                    "articles": orphans
                });
            }

            return cc;
        }).property("articles")
    });
});