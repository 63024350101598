define("life-light-web/components/rating-widget", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		tagName: "span",
		classNames: ["rating"],

		length: undefined,
		ratings: "1 2 3 4 5 6 7",

		voteOnce: false,
		readonly: false,

		initialize: (function () {
			var ratingsArray;
			var count = this.get("length");

			if (count) {
				ratingsArray = [];
				for (var i = parseInt(count); i > 0; i--) {
					ratingsArray.push(i);
				}
			} else {
				ratingsArray = JSON.parse("[" + this.get("ratings").replace(/ /g, ",") + "]");
			}

			this.set("ratingsArray", ratingsArray);
		}).on("init"),

		didInsertElement: function didInsertElement() {
			var rating = Math.round(this.get("rating"));
			this.$("input[value=" + rating + "]").trigger("click");
		},

		ratingObserver: (function () {
			if (this.get("rated") && this.get("voteOnce")) {
				this.$("input").prop("disabled", this.get("rated") ? true : false);
			} else {
				var rating = Math.round(this.get("rating"));
				if (rating) {
					this.$("input[value=" + rating + "]").prop("checked", true);
				} else {
					this.$("input:checked").prop("checked", false);
				}
			}
		}).observes("rating"),

		click: function click(e) {
			var self = this;
			if (!this.get("rated") || !this.get("voteOnce")) {
				var target = self.$(e.target);
				if (target.is("input") && !isNaN(e.clientX)) {
					var value = parseInt(target.val());
					this.get("onRated")(value);
					this.set("rated", value);
				}
			}
		}
	});
});