define('life-light-web/controllers/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),

    actions: {
      authenticateWithFacebook: function authenticateWithFacebook() {
        var self = this;
        this.get('session').authenticate('authenticator:torii', 'facebook-oauth2').then(function (authentication) {
          // Ember.Logger.info("User: %@".fmt(authentication.user));
          _ember['default'].Logger.info("Logged in");
          self.transitionToRoute("home");
        });
      }

      // authenticate() {
      //    let { identification, password } = this.getProperties('identification', 'password');
      //    this.get('session').authenticate('authenticator:torii', identification, password).catch((reason) => {
      //      this.set('errorMessage', reason.error);
      //    });
      //  }
    }
  });
});