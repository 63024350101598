define("life-light-web/components/vertical-text", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		tagName: "h3",
		classNames: "vertical-text-stuff",
		offset: 0,

		didInsertElement: function didInsertElement() {
			this.titleObserver();
			return;
			var width = this.$().width();
			var height = 0 - this.get("offset") - this.$().height();
			// var transformStr = "translateY(" + width + "px) translateX(" + height + "px) rotate(270deg)";
			var transformStr = "translateY(" + width + "px) translateX(" + height + "px) rotate(270deg)";
			this.$().css({ transform: transformStr });
		},

		titleObserver: (function () {
			_ember["default"].Logger.info("*********************" + this.get("title"));
			_ember["default"].Logger.info("resizeCounter: " + this.get("resizeCounter"));
			this.$().text(this.get("title"));
			this.$().css('transform', 'none');

			var self = this;
			_ember["default"].run.once(function () {
				var width = self.$().width();
				var windowHeight = window.innerHeight;
				var scale = 1.0;

				if (parseFloat(width) + 30 + 50 + 50 > windowHeight) {
					var fontSize = Math.floor(24 * windowHeight / (parseFloat(width) + 30 + 50 + 50));
					scale = windowHeight / (parseFloat(width) + 30 + 50 + 50);
					// self.$().css("font-size", (parseFloat(self.$().css("font-size")) - 4) + "px");
					self.$().css("transform", "scale(" + scale + ", " + scale + ")");
					// self.$().css("font-size", fontSize + "px");
					width = self.$().width();
				} else {
					// self.$().css("font-size", "24px");
					self.$().css("transform", "scale(1.0, 1.0)");
				}

				var height = 0 - self.get("offset") - self.$().height();
				// var transformStr = "translateY(" + width + "px) translateX(" + height + "px) rotate(270deg)";
				// var transformStr = "translateY(" + width + "px) translateX(" + height + "px) rotate(270deg)";
				var transformStr = "scale(" + scale + ", " + scale + ") translateY(" + width + "px) translateX(" + height + "px) rotate(270deg)";
				self.$().css({ transform: transformStr });
			});
		}).observes('title', 'resizeCounter')
	});
});