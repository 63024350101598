define('life-light-web/transforms/array', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	exports['default'] = _emberData['default'].Transform.extend({
		deserialize: function deserialize(serialized) {
			return _ember['default'].typeOf(serialized) === "array" ? serialized : [];
		},

		serialize: function serialize(deserialized) {
			var type = _ember['default'].typeOf(deserialized);
			if (type === 'array') {
				return deserialized;
			} else if (type === 'string') {
				return deserialized.split(',').map(function (item) {
					return _ember['default'].$.trim(item);
				});
			} else {
				return [];
			}
		}
	});
});