define('life-light-web/components/articles-index', ['exports', 'ember', 'lodash/lodash'], function (exports, _ember, _lodashLodash) {
	exports['default'] = _ember['default'].Component.extend({
		session: _ember['default'].inject.service(),

		initialize: (function () {
			// Ember.Logger.info("Initted");
			var selectedTag = this.get("selectedTag");
			var checkedTags = this.get("checkedTags");

			if (checkedTags.length === 0) {
				this.set("controller.selectedTag", null);
			} else if (selectedTag) {
				checkedTags.findBy("text", selectedTag).set("checked", true);
			}
		}).on("init"),

		didInsertElement: function didInsertElement() {
			this.get("setArticleListMinHeight")(this);
			if (this.get('articles.showTagsByDefault')) {
				this.set('tagVisibility', true);
			}
			/*		var articleList = this.$("section.article-list");
   		articleList.css("min-height", articleList.height());
   */
		},

		setArticleListMinHeight: function setArticleListMinHeight(context) {
			var articleList = context.$("section.article-list");
			articleList.css("min-height", articleList.height());
		},

		actions: {
			toggleTag: function toggleTag(tag) {
				this.get("checkedTags").findBy("id", tag.id).toggleProperty("checked");
				// this.$().closest("body").find("#blank_focus").focus();
				this.$("#tag_defocus").focus();
				this.get("highlightTaggedArticles")(this);
			},

			clearAllTags: function clearAllTags() {
				this.get("checkedTags").forEach(function (tag) {
					tag.set("checked", false);
				});
				// this.$().closest("body").find("#blank_focus").focus();
				this.$("#tag_defocus").focus();
			},

			toggleTags: function toggleTags() {
				this.toggleProperty("tagVisibility");
			},

			toggleEdit: function toggleEdit() {
				this.toggleProperty("isEditingTags");
				// Ember.$("#blank_focus").focus();
				this.$("#tag_defocus").focus();
			},

			deleteTag: function deleteTag(tag) {
				var self = this;
				$('#tag-delete-modal span.tag-name').text(tag.get('text')).closest('.modal').modal({ backdrop: 'static', keyboard: false }).one('click', '#delete', function (e) {
					self.get("onTagDelete")(tag);
					_ember['default'].Logger.info('Drop the tag');
				});

				//** this.get("onTagDelete")(tag);
				// this.$('span[data-tagid="+id+"]').remove();
			},

			// updateTagName: function(id, name) {
			updateTagName: function updateTagName(tag) {
				var self = this;
				var modifyingTag = tag.changedAttributes().text;

				$('#tag-delete-modal').data('data-tag', tag).modal({ backdrop: 'static', keyboard: false }).on('shown.bs.modal', function (e) {
					if (modifyingTag) {
						$('.modal-body p', this).html('Are you sure you want to change the tag name from <strong>' + tag.get('data.text') + '</strong> to <strong>' + tag.get('text') + '</strong>?');
						$('.modal-title', this).text('Modify Tag');
						$('.modal-footer .btn-primary', this).text('Yes, modify the tag');
					}
				}).one('click', '#delete', function (e) {
					if (modifyingTag) {
						self.get("onTagUpdate")(tag);
					} else {
						self.get("onTagDelete")(tag);
						_ember['default'].Logger.info('Drop the tag');
					}
				});

				// this.get("onTagUpdate")(id, name);
				//** this.get("onTagUpdate")(tag);
			},

			addTag: function addTag() {
				this.get("onTagUpdate")();
			}
		},

		checkedTags: (function () {
			var tagList = this.get("sortedTags");
			// var tagList = this.get("articles.tagList");
			if (!!!tagList) {
				return null;
			}

			var checkedTags = tagList.map(function (tag) {
				return _ember['default'].Object.create({
					model: tag,
					id: tag.id,
					text: tag.get("text"),
					checked: tag.checked
				});
			});

			return checkedTags;
			// }.property("articles.tagList", "articles.tagList.[]"),
		}).property("sortedTags", "sortedTags.[]"),

		newTagAdded: (function () {
			var self = this;
			var tags = self.get("sortedTags");
			_ember['default'].run.next(function () {
				if (tags.objectAt(0).currentState.stateName === "root.loaded.created.uncommitted") {
					self.$('.tag-list .editable-button:nth(0) input').focus();
				}
			});
		}).observes("sortedTags.[]"),

		noTagsChecked: (function () {
			return !!!this.get("checkedTags").any(function (tag) {
				return tag.get("checked");
			});
		}).property("checkedTags.@each.checked"),

		checkedTagsObserver: (function () {
			this.get("highlightTaggedArticles")(this);
		}).observes("checkedTags", "checkedTags.@each.checked"),

		highlightTaggedArticles: function highlightTaggedArticles(context) {
			var checkedTagList = context.get("checkedTags").filterBy("checked").mapBy("id");
			var searching = checkedTagList.length !== 0;
			context.get("model").forEach(function (article /*, index*/) {
				var articleTags = article.get("tags").mapBy("id");
				var tagged = _lodashLodash['default'].intersection(checkedTagList, articleTags).length === checkedTagList.length;
				article.set("searching", searching);
				article.set("searched", searching && tagged);
				article.set("inactive", searching && !tagged);
			});
		},

		tagVisibility: false,

		tagVisibilityObserver: (function () {
			if (this.get("tagVisibility")) {
				_ember['default'].run.next(this, function () {
					this.$('[data-toggle="tooltip"]').tooltip();
				});
			}
		}).observes("tagVisibility"),

		showTagsByDefaultObserver: (function () {
			if (this.get('articles.showTagsByDefault')) {
				this.set('tagVisibility', true);
			};
		}).observes('articles.showTagsByDefault'),

		showTags: (function () {
			return this.get("tagVisibility") || this.get("selectedTag");
		}).property("selectedTag", "tagVisibility"),

		showTagNoResults: (function () {
			var model = this.get("model");
			return model.every(function (article) {
				return article.get("searching");
			}) && !model.any(function (article) {
				return article.get("searched");
			});
		}).property("model.@each.searching", "model.@each.searched"),

		sortedTags: (function () {
			var tags = this.get('articles.tagList');
			if (!!!tags) {
				return [];
			}

			return tags.sortBy('text');
		}).property('articles.tagList', 'articles.tagList.[]'),

		displaySystemMessage: (function (message) {
			_ember['default'].Logger.info(message);
		}).on('displaySystemMessage')
	});
});