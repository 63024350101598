define("life-light-web/helpers/pluralizer", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.extend({
    compute: function compute(params, hash) {
      var singular = params[0];
      var plural = params[1];
      var value = hash.value;
      //** ECMA6    return "%@ %@".fmt(value, value === 1 ? singular : plural);
      return value + " " + (value === 1 ? singular : plural);
      // return `${currency}${cents * 0.01}`;
    }
  });
});