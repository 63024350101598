define("life-light-web/templates/energy", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 144
            },
            "end": {
              "line": 6,
              "column": 223
            }
          },
          "moduleName": "life-light-web/templates/energy.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("‘Your Aura and Electromagnetic Fields’");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "life-light-web/templates/energy.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "class", "page-title");
        dom.setAttribute(el1, "style", "display:block");
        var el2 = dom.createTextNode("Energy");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createElement("img");
        dom.setAttribute(el2, "src", "assets/images/man-aura-ill.png");
        dom.setAttribute(el2, "class", "energy-man pull-right");
        dom.setAttribute(el2, "alt", "");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "text-large");
        var el3 = dom.createTextNode("Energy equates to life force or Qi in the body.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" Energy moves in spirals through your body, around bones, through joints and organs, blood vessels, nerves and fascia creating myriads of pattern and light. This energy flow is the electrical system of your body. It is organized by and works with other systems such as skeletal, muscular, nervous, endocrine, vascular, and digestive systems.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Some schools of thought represent your aura as a manifestation of the electrical functioning of your brain, heart and other organs. Your aura is more than that, in fact it is quite the reverse. Your overall wellness is more a manifestation of the ‘wellness’ of your aura.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "text-large");
        var el3 = dom.createTextNode("Seven major energy centers known as chakras");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" represent the core energy of your body with smaller chakras or sub-chakras at every joint and within each organ. Energy flow between chakras and sub-chakras forms the meridians of your body.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "text-large");
        var el3 = dom.createTextNode("The ‘light’ from this electrical system is known as the human aura.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" For more details please read the article ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        dom.setAttribute(el1, "class", "hidden");
        var el2 = dom.createTextNode("\n	Hands-on-healing is the art of giving energy to another so that they may heal. For the healer, giving energy can be gentle and effortless.\n	For the recipient, energy healing can ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("strong");
        dom.setAttribute(el2, "class", "focus");
        var el3 = dom.createTextNode("transform");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" their body and life as well as deepen their connection to Spirit. I have studied and practiced this modality for over 20 years.\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [8]), 2, 2);
        morphs[1] = dom.createMorphAt(fragment, 12, 12, contextualElement);
        return morphs;
      },
      statements: [["block", "link-to", ["article", "aura"], [], 0, null, ["loc", [null, [6, 144], [6, 235]]]], ["content", "outlet", ["loc", [null, [11, 0], [11, 10]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});