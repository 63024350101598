define("life-light-web/components/article-timeline", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		classNames: ["timeline"],
		classNameBindings: ["isDragging:active"],
		articleId: null,
		currentId: null,
		index: -1,
		isDragging: false,
		dragged: false,

		draggingTitle: (function () {
			var index = this.get("index");
			return index < 0 ? null : this.get("articles").objectAt(index).get("title");
		}).property("index"),

		click: function click(e) {
			if (!this.$(e.currentTarget).hasClass("ui-draggable-handle")) {
				var index = e.offsetX / this.$().width() * (this.get("articles.length") - 1);
				_ember["default"].Logger.info(index, Math.round(index));
				this.set("index", Math.round(index));
				var article = this.get("articles")[this.get("index")];
				this.get("onIndexChange")(article.id);
			}
		},

		didInsertElement: function didInsertElement() {
			var self = this;

			_ember["default"].run.later(function () {
				self.setSliderPosition();
			}, 100);

			this.$().click(function () /*e*/{
				// var position = e.;
			});

			this.$(".slider").draggable({
				axis: "x",
				containment: "parent",
				scrollable: false,
				cursor: "ew-resize",
				handle: ".handle", // Needs an element inside .slider to have a handle
				drag: function drag() /*event, ui*/{
					self.set("isDragging", true);
					var articleCount = self.get("articles.length");
					var timelineWidth = self.$().width() - self.$(".slider").width();
					var index = parseInt(parseFloat(self.$(".slider").css("left")) / timelineWidth * (articleCount - 1));
					self.set("index", index);
					self.$(".title").position({
						my: "center top",
						at: "center bottom",
						of: ".slider",
						collision: "fit",
						within: ".timeline",
						using: function using(pos /*, feedback*/) {
							self.$(".title").css({ "left": pos.left, "top": pos.top + 12 });
						}
					});
				},
				stop: function stop() /*event, ui*/{
					self.$().off("mousemove.timeline");
					var article = self.get("articles")[self.get("index")];
					self.get("onIndexChange")(article.id);
					self.set("dragged", true);
					self.set("isDragging", false);
				}
			});
		},

		indexObserver: (function () {}).observes("index"),

		articleObserver: (function () {
			this.setSliderPosition();
		}).observes("article.model.id"),

		setSliderPosition: function setSliderPosition() {
			if (this.get("dragged")) {
				this.set("dragged", false);
				return;
			}

			var articleCount = this.get("articles.length");
			var timelineWidth = this.$().width() - this.$(".slider").width();
			var index = this.get("articles").indexOf(this.get("article"));
			var position = parseFloat(index / (articleCount - 1) * timelineWidth);
			this.$(".slider").css("left", position + "px");
		}
	});
});