define('life-light-web/mixins/scrolling-dom-methods', ['exports', 'ember'], function (exports, _ember) {
  exports.bindOnScroll = bindOnScroll;
  exports.unbindOnScroll = unbindOnScroll;

  /**
    This object provides the DOM methods we need for our Mixin to bind to scrolling
    methods in the browser. By removing them from the Mixin we can test them
    easier.
  
    @class ScrollingDOMMethods
    @module Discourse
  **/

  function bindOnScroll(onScrollMethod, name) {
    name = name || 'default';
    _ember['default'].$(document).bind('touchmove.discourse-' + name, onScrollMethod);
    _ember['default'].$(window).bind('scroll.discourse-' + name, onScrollMethod);
  }

  function unbindOnScroll(name) {
    name = name || 'default';
    _ember['default'].$(window).unbind('scroll.discourse-' + name);
    _ember['default'].$(document).unbind('touchmove.discourse-' + name);
  }
});