define('life-light-web/controllers/application', ['exports', 'ember', 'life-light-web/config/environment', 'ember-simple-auth/mixins/application-route-mixin', 'moment'], function (exports, _ember, _lifeLightWebConfigEnvironment, _emberSimpleAuthMixinsApplicationRouteMixin, _moment) {
    exports['default'] = _ember['default'].Controller.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
        session: _ember['default'].inject.service(),
        homeController: _ember['default'].inject.controller("home"),

        actions: {
            invalidateSession: function invalidateSession() {
                this.get('session').invalidate();
            },

            toggleArticlesSubMenu: function toggleArticlesSubMenu() {
                if (!this.get("nested")) {
                    this.toggleProperty("isArticlesSubMenuVisible");
                }
            },

            scrollToTop: function scrollToTop() {
                window.scrollTo(0, 0);
            },

            userViewClick: function userViewClick(key) {
                var self = this;

                _ember['default'].Logger.info(key + " clicked in the application controller");
                _ember['default'].$.getJSON(_lifeLightWebConfigEnvironment['default'].APP.API_HOST + '/api/userviews/' + key + '/increment', {}, function (data) {
                    self.set("userviews", data);
                });
            },

            openOptionsModal: function openOptionsModal() {
                // TODO: Remove when everything goes to application controller userview
                var self = this;
                self.store.findAll("userview").then(function (views) {
                    self.set("userviews", views);
                });
            },

            /*        openModal: function (modalName, model) {
                        if (modalName === "optionsModal") {
                            var currentRoute = this.get("controller.currentRouteName");
                            model = this.modelFor(currentRoute);
                            if (!!!model)
                                model = { title: currentRoute };
            
            
                            if (typeof model === "object")
                                model = new Ember.Object(model);
            
                            if (!model.title) {
                                //            if (!model.get("title")) {
                                model.set("title", currentRoute.capitalize());
                            }
            
                            model.set("routeName", currentRoute);
            
                            this.controllerFor(modalName).set("model", model);
                        }
            
                        return this.render(modalName, {
                            into: "application",
                            outlet: "modal"
                        });
                    },
            */

            clearLatestViewedArticleId: function clearLatestViewedArticleId() {
                this.get("homeController").send("clearLatestViewedArticleId");
            }
        },

        isAuthenticated: (function () {
            return this.get("session").isAuthenticated;
        }).property(),

        isHomePage: (function () {
            return this.get("currentPath") === "home";
        }).property("currentPath"),

        isPeriodicalsPage: (function () {
            return this.get("currentPath").startsWith("article");
        }).property("currentPath"),

        isAuthenticationControlVisible: (function () {
            var currentPath = this.get("currentPath");
            var result;

            switch (currentPath) {
                case "articles.index":
                case "articles.article.index":
                case "credentials":
                case "testimonials":
                    result = true;
                    break;
                default:
                    result = false;
            }

            return result;
        }).property("currentPath"),

        article: _ember['default'].inject.controller(),
        articles: _ember['default'].inject.controller(),

        headerText: (function () {
            var text;
            if (this.get("currentPath") && this.get("currentPath").indexOf("shoppernews") !== -1) {
                text = "Perceptions and reflections that<br/>shape our consciousness and our reality";
            } else if (this.get("currentPath") && this.get("currentPath").indexOf("shopper") !== -1) {
                text = "Perceptions and reflections that<br/>shape our consciousness and our reality";
            } else {
                switch (this.get("currentRouteName")) {
                    case "home":
                        text = "<span style=\"letter-spacing: 0.13em\">LifeLight Center is dedicated to</span><br/><span style=\"letter-spacing: 0.16em\">individual empowerment through</span><br/><span style=\"letter-spacing: 0.16em\">healing and education</span>";break;
                    case "services":
                        text = "Rose Carey perceives your electrical field, your aura,<br/>as frequency and geometric pattern.<br/>Interpreting and repairing these patterns is her life's work.";break;
                    case "energy":
                        text = "Energy work relies on principles of science to restore<br/>the integrity of your electrical field, your aura.";break;
                    case "events":
                        text = "Teachings, meditations,<br/>health fairs and community social events,<br/>all are welcome";break;
                    case "articles":
                        text = "Perceptions and reflections that<br/>shape our consciousness and our reality";break;
                    case "manwithdeereyes":
                        text = "Perceptions and reflections that<br/>shape our consciousness and our reality";break;
                    case "aura":
                        text = "Perceptions and reflections that<br/>shape our consciousness and our reality";break;
                    case "articles.index":
                        text = "Perceptions and reflections that<br/>shape our consciousness and our reality";break;
                    case "article.index":
                        text = "Perceptions and reflections that<br/>shape our consciousness and our reality";break;
                    case "articles.index":
                        text = "Perceptions and reflections that<br/>shape our consciousness and our reality";break;
                    case "credentials":
                        text = "Rose Carey is referred by<br/>Dr. Andrew Weil’s Arizona Center for Integrative Medicine<br/>as a practitioner of energy medicine.";break;
                    case "associates":
                        text = "Reversing illness means<br/>rebuilding your whole being.";break;
                    case "testimonials":
                        text = "Deepening your experience of healing";break;
                    case "pressurepoint":
                        text = "Your body heals best<br />when all of its systems are in harmony";break;
                    case "workshop":
                        text = "Energy Medicine Handbook<br /><span class=\"ancient-knowledge\">ancient knowledge returns</span><br /><span class=\"header-text-sub\">a workshop for psychologists, mental health professionals, body workers and healers</span>";break;
                    case "birthday":
                        text = "Birthday Wishes and Rabbit Kisses";break;
                    case "tracking":
                        text = "Tracking your issues is the first step towards fixing them<br /><em>&mdash;Merci Theopholis Bear</em>";break;
                    case "page-not-found":
                        text = "Finding your way through a maze of pages&hellip;";break;
                    default:
                        text = "";
                }
            }

            return text;
        }).property("currentRouteName", "currentPath"),

        copyright_year: (function () {
            return (0, _moment['default'])().format("YYYY");
        }).property()
    });
});