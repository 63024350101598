define("life-light-web/templates/article", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "life-light-web/templates/article.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "periodical-component", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 29], [1, 34]]]]], [], []], "articles", ["subexpr", "@mut", [["get", "articles", ["loc", [null, [1, 44], [1, 52]]]]], [], []], "session", ["subexpr", "@mut", [["get", "session", ["loc", [null, [1, 61], [1, 68]]]]], [], []], "customerRatings", ["subexpr", "@mut", [["get", "customerRatings", ["loc", [null, [1, 85], [1, 100]]]]], [], []], "showRatingData", ["subexpr", "@mut", [["get", "showRatingData", ["loc", [null, [1, 116], [1, 130]]]]], [], []], "showFontSize", ["subexpr", "@mut", [["get", "showFontSize", ["loc", [null, [1, 144], [1, 156]]]]], [], []], "anchor", ["subexpr", "@mut", [["get", "anchor", ["loc", [null, [1, 164], [1, 170]]]]], [], []], "latestArticle", ["subexpr", "@mut", [["get", "articles.latestArticle", ["loc", [null, [1, 185], [1, 207]]]]], [], []], "bookmarkId", ["subexpr", "@mut", [["get", "articles.bookmarkId", ["loc", [null, [1, 219], [1, 238]]]]], [], []], "isBookmarked", ["subexpr", "@mut", [["get", "isBookmarked", ["loc", [null, [1, 252], [1, 264]]]]], [], []], "searchTerm", ["subexpr", "@mut", [["get", "searchTerm", ["loc", [null, [1, 276], [1, 286]]]]], [], []], "fontSize", ["subexpr", "@mut", [["get", "fontSize", ["loc", [null, [1, 296], [1, 304]]]]], [], []], "onFontSizeChanged", ["subexpr", "action", ["fontSizeChanged"], [], ["loc", [null, [1, 323], [1, 349]]]], "addNewTag", ["subexpr", "action", ["addNewTag"], [], ["loc", [null, [1, 360], [1, 380]]]]], ["loc", [null, [1, 0], [1, 382]]]]],
      locals: [],
      templates: []
    };
  })());
});