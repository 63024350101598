define("life-light-web/templates/testimonials", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "life-light-web/templates/testimonials.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "new-testimonial pull-right");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "glyphicon glyphicon-comment");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Submit a testimonial");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [["element", "action", ["edit"], [], ["loc", [null, [4, 4], [4, 21]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 45,
              "column": 0
            }
          },
          "moduleName": "life-light-web/templates/testimonials.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-xs-12");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "panel panel-primary panel-testimonial");
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "panel-heading");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("h3");
          dom.setAttribute(el5, "class", "panel-title");
          var el6 = dom.createTextNode("Submit your testimonial…");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "panel-body");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "row");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "class", "form-group col-xs-12");
          var el7 = dom.createTextNode("\n							");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7, "for", "text");
          var el8 = dom.createTextNode("Testimonial text");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("br");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n							");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "row");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "class", "form-group col-xs-6");
          var el7 = dom.createTextNode("\n							");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7, "for", "name");
          var el8 = dom.createTextNode("Name");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("br");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n							");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "class", "form-group col-xs-6");
          var el7 = dom.createTextNode("\n							");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7, "for", "bonafida");
          var el8 = dom.createTextNode("Bona fide");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("br");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n							");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "row");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "class", "form-group col-xs-12");
          var el7 = dom.createTextNode("\n							");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7, "for", "location");
          var el8 = dom.createTextNode("Location");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("br");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n							");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n						");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "panel-footer");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "form-group");
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6, "class", "btn btn-sm btn-primary");
          var el7 = dom.createTextNode("Submit");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n						");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6, "class", "btn btn-sm btn-default");
          var el7 = dom.createTextNode("Cancel");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1, 1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(element0, [5, 1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 4, 4);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 4, 4);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 4, 4);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [5, 1]), 4, 4);
          morphs[4] = dom.createElementMorph(element4);
          morphs[5] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [["inline", "textarea", [], ["id", "text", "value", ["subexpr", "@mut", [["get", "text", ["loc", [null, [16, 34], [16, 38]]]]], [], []], "class", "form-control", "placeholder", "Enter your testimonial...", "rows", "3"], ["loc", [null, [16, 7], [16, 110]]]], ["inline", "input", [], ["id", "name", "value", ["subexpr", "@mut", [["get", "signature", ["loc", [null, [22, 31], [22, 40]]]]], [], []], "class", "form-control", "placeholder", "Enter your name..."], ["loc", [null, [22, 7], [22, 96]]]], ["inline", "input", [], ["id", "bonafida", "value", ["subexpr", "@mut", [["get", "bonafida", ["loc", [null, [26, 35], [26, 43]]]]], [], []], "class", "form-control", "placeholder", "Enter your bona fide..."], ["loc", [null, [26, 7], [26, 104]]]], ["inline", "input", [], ["id", "location", "value", ["subexpr", "@mut", [["get", "location", ["loc", [null, [32, 35], [32, 43]]]]], [], []], "class", "form-control", "placeholder", "Enter your location..."], ["loc", [null, [32, 7], [32, 103]]]], ["element", "action", ["submit"], [], ["loc", [null, [38, 14], [38, 33]]]], ["element", "action", ["cancel"], [], ["loc", [null, [39, 14], [39, 33]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 2
                },
                "end": {
                  "line": 52,
                  "column": 2
                }
              },
              "moduleName": "life-light-web/templates/testimonials.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("		");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "testimonial-component", [], ["testimonial", ["subexpr", "@mut", [["get", "testimonial", ["loc", [null, [51, 38], [51, 49]]]]], [], []], "isAuthenticated", ["subexpr", "@mut", [["get", "session.isAuthenticated", ["loc", [null, [51, 66], [51, 89]]]]], [], []], "onApprove", ["subexpr", "action", ["approve"], [], ["loc", [null, [51, 100], [51, 118]]]], "onDelete", ["subexpr", "action", ["delete"], [], ["loc", [null, [51, 128], [51, 145]]]]], ["loc", [null, [51, 2], [51, 147]]]]],
            locals: ["testimonial"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 1
              },
              "end": {
                "line": 53,
                "column": 1
              }
            },
            "moduleName": "life-light-web/templates/testimonials.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            var el2 = dom.createTextNode("Submitted");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "submittedTestimonials", ["loc", [null, [50, 10], [50, 31]]]]], [], 0, null, ["loc", [null, [50, 2], [52, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 2
                },
                "end": {
                  "line": 59,
                  "column": 2
                }
              },
              "moduleName": "life-light-web/templates/testimonials.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("		");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "testimonial-component", [], ["testimonial", ["subexpr", "@mut", [["get", "testimonial", ["loc", [null, [58, 38], [58, 49]]]]], [], []], "isAuthenticated", ["subexpr", "@mut", [["get", "session.isAuthenticated", ["loc", [null, [58, 66], [58, 89]]]]], [], []], "onApprove", ["subexpr", "action", ["approve"], [], ["loc", [null, [58, 100], [58, 118]]]], "onDelete", ["subexpr", "action", ["delete"], [], ["loc", [null, [58, 128], [58, 145]]]]], ["loc", [null, [58, 2], [58, 147]]]]],
            locals: ["testimonial"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 1
              },
              "end": {
                "line": 60,
                "column": 1
              }
            },
            "moduleName": "life-light-web/templates/testimonials.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            var el2 = dom.createTextNode("Approved");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "approvedTestimonials", ["loc", [null, [57, 10], [57, 30]]]]], [], 0, null, ["loc", [null, [57, 2], [59, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 2
                },
                "end": {
                  "line": 66,
                  "column": 2
                }
              },
              "moduleName": "life-light-web/templates/testimonials.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("		");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "testimonial-component", [], ["testimonial", ["subexpr", "@mut", [["get", "testimonial", ["loc", [null, [65, 38], [65, 49]]]]], [], []], "isAuthenticated", ["subexpr", "@mut", [["get", "session.isAuthenticated", ["loc", [null, [65, 66], [65, 89]]]]], [], []], "onApprove", ["subexpr", "action", ["approve"], [], ["loc", [null, [65, 100], [65, 118]]]], "onDelete", ["subexpr", "action", ["delete"], [], ["loc", [null, [65, 128], [65, 145]]]]], ["loc", [null, [65, 2], [65, 147]]]]],
            locals: ["testimonial"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 1
              },
              "end": {
                "line": 67,
                "column": 1
              }
            },
            "moduleName": "life-light-web/templates/testimonials.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            var el2 = dom.createTextNode("Rejected");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "rejectedTestimonials", ["loc", [null, [64, 10], [64, 30]]]]], [], 0, null, ["loc", [null, [64, 2], [66, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 0
            },
            "end": {
              "line": 68,
              "column": 0
            }
          },
          "moduleName": "life-light-web/templates/testimonials.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "submittedTestimonials", ["loc", [null, [48, 7], [48, 28]]]]], [], 0, null, ["loc", [null, [48, 1], [53, 8]]]], ["block", "if", [["get", "approvedTestimonials", ["loc", [null, [55, 7], [55, 27]]]]], [], 1, null, ["loc", [null, [55, 1], [60, 8]]]], ["block", "if", [["get", "rejectedTestimonials", ["loc", [null, [62, 7], [62, 27]]]]], [], 2, null, ["loc", [null, [62, 1], [67, 8]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 1
              },
              "end": {
                "line": 71,
                "column": 1
              }
            },
            "moduleName": "life-light-web/templates/testimonials.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "testimonial-component", [], ["testimonial", ["subexpr", "@mut", [["get", "testimonial", ["loc", [null, [70, 38], [70, 49]]]]], [], []], "isAuthenticated", ["subexpr", "@mut", [["get", "session.isAuthenticated", ["loc", [null, [70, 66], [70, 89]]]]], [], []], "onApprove", ["subexpr", "action", ["approve"], [], ["loc", [null, [70, 100], [70, 118]]]], "onDelete", ["subexpr", "action", ["delete"], [], ["loc", [null, [70, 128], [70, 145]]]]], ["loc", [null, [70, 2], [70, 147]]]]],
          locals: ["testimonial"],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 0
            },
            "end": {
              "line": 72,
              "column": 0
            }
          },
          "moduleName": "life-light-web/templates/testimonials.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "model", ["loc", [null, [69, 9], [69, 14]]]]], [], 0, null, ["loc", [null, [69, 1], [71, 10]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 74,
            "column": 0
          }
        },
        "moduleName": "life-light-web/templates/testimonials.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "class", "page-title");
        dom.setAttribute(el1, "style", "display:block");
        var el2 = dom.createTextNode("LifeLight Center Testimonials");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        return morphs;
      },
      statements: [["block", "unless", [["get", "isInputtingNew", ["loc", [null, [3, 10], [3, 24]]]]], [], 0, 1, ["loc", [null, [3, 0], [45, 11]]]], ["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [47, 6], [47, 29]]]]], [], 2, 3, ["loc", [null, [47, 0], [72, 7]]]], ["content", "outlet", ["loc", [null, [73, 0], [73, 10]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});