define("life-light-web/models/associate", ["exports", "ember-data"], function (exports, _emberData) {
	exports["default"] = _emberData["default"].Model.extend({
		name: _emberData["default"].attr("string"),
		description: _emberData["default"].attr("string"),
		uri: _emberData["default"].attr("string")
	}) /*.reopenClass({
    FIXTURES: [
    {
    id: 1,
    name: "Harmony of Wellness",
    description: "<p>As part of LifeLight Center, Harmony of Wellness LLC is an educational organization that extends concepts for healing to a greater audience. Current teachings include the Aligned-Essence Wellness Series, meditative self-healing CD's and books.</p><p>Teach workshops in your area through our Teacher Certification program.</p>",
    uri: "http://harmonyofwellness.com/"
    },
    {
    id: 2,
    name: "Steven Helpern",
    description: "<p>Steven Halpern is an award-winning composer, recording artist and producer whose healing music has helped millions worldwide to experience the blessings and benefits of deep relaxation and inner peace.</p>",
    uri: "http://www.innerpeacemusic.com/"
    },
    {
    id: 3,
    name: "Arizona Enneagram Association",
    description: "<p>The Arizona Enneagram Association (AEA) was formed by a group of dedicated Enneagram teachers in the fall of 2008 in order to expand the foundational work that had been done in the past in bringing the teachings of the Enneagram to the residents of Arizona.</p>",
    uri: "http://azenneagram.com/"
    },
    {
    id: 4,
    name: "The Healing Light Center",
    description: "<p>A modern mystery school, the Crucible Program trains medical practicioners along with others from varying professions in the tradition of the healing arts. Classes and workshops are held around the world in a fashion that allows students to train when and where they can.</p>",
    uri: "http://rosalynlbruyere.org/"
    },
    {
    id: 5,
    name: "Debra Mahan",
    description: "<p>JRM Management, Healthcare Consulting, San Antonio, Texas</p>",
    uri: "http://www.linkedin.com/pub/debra-mahan/4/a3/870"
    },
    {
    id: 6,
          name: "Anita Pietrofitta",
          description: "<p>L.Ac, Dipl. Oriental Medicine (NCCAOM), Owner, Healing Arts Center, Tahoe City, CA<br />Licensed Acupuncturist and Herbalist, Practitioner of Chinese Medicine, Doctoral Candidate</p>",
          uri: "http://healingtahoe.com/anita-pietrofitta"
      },
    {
    id: 7,
          name: "Diane Stallings",
          description: "<p>RN, owner of Joystream Health LLC, Holistic Health, Reiki, Healing Touch</p>",
          uri: "http://joystream.net/"
      },
    {
    id: 8,
          name: "Gabrielle Lawrence",
          description: "Ph.D., Psychologist, Presenter, Trainer, Coach in Phoenix, Arizona</p>",
          uri: "http://www.health4relationships.com/"
      }
      ]
    })*/;
});