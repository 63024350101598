define("life-light-web/routes/testimonials", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Route.extend({
		model: function model() {
			return this.store.findAll("testimonial") /*.shuffle()*/;
		},

		setupController: function setupController(controller, model) {
			controller.set("model", model);
			controller.set("isInputtingNew", false);
		}
	});
});