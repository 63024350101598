define("life-light-web/models/testimonial", ["exports", "ember-data"], function (exports, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({
        testimonial: _emberData["default"].attr("string"),
        signature: _emberData["default"].attr("string"),
        bonaFida: _emberData["default"].attr("string"),
        location: _emberData["default"].attr("string"),
        isApproved: _emberData["default"].attr("nullable-boolean")
    }) /*.reopenClass({
         FIXTURES: [
             { id: 1, testimonial: 'As a psychologist certified in Somatic Experiencing work, I have studied and been involved in the field of Energy Medicine for some time. I have worked with Rosie Carey as a student and client and find her knowledge and application of the theory and practice of Energy Medicine to be extraordinary.  She is teacher and practitioner and excels as both.  Her understanding of the energy systems of the body and ability to bring healing and balance to them is a gift for all who work with her.', signature: 'Gabrielle Lawrence, Ph.D.', bonaFida: 'Licensed Psychologist', location: 'Phoenix, AZ' },
             { id: 2, testimonial: 'Rosie has been the driving force for my healing journey. The sessions with her build upon each other and accelerate the transformation to a healthy balanced body.  I have and continue to be a very fortunate recipient of her abilities.', signature: 'Somer Starr Osinski', location: 'Phoenix, AZ' },
             { id: 3, testimonial: 'I have been a healthcare worker/therapist for thirty-four years and have seen a multitude of therapists and body workers, professionally and personally.  I knew from the first session that Rosie and her work were different and I had been led to the next level of my growth.  I have referred my dearest friends and clients to her so they may move forward as well!', signature: 'Susan Neva L.P.N., C.C.T.', bonaFida: 'Reiki Master, Tong Ren Therapist, Homeopath', location: 'Boston, MA' },
             { id: 4, testimonial: 'Rosie’s abilities as an energy healer are a priceless aspect of anyone’s journey toward regaining and maintaining health, especially when dealing with reestablishing balance after trauma.', signature: 'Anita Pietrofitta', bonaFida: 'M.S., L.Ac., Dipl.O.M.', location: 'San Francisco, CA'},
             { id: 5, testimonial: 'Rosie has such a depth and breadth of knowledge and expertise. There is nothing better than the feeling and lasting healing results when one leaves her session. She is a true gift to humanity.', signature: 'Glenyss Lim', bonaFida: 'RN Holistic Healer and Personal Growth Facilitator', location: 'Phoenix, AZ' },
             { id: 6, testimonial: 'Rosie should be considered a National Treasure for her amazing healing skills—from spinal balancing to Neuromuscular Therapy to Divine Energy Healing, which has provided transformational healing for me and for many others well known to me. In her capable hands, you can trust that relief is on the way.', signature: 'Diane Stallings', bonaFida: 'RN, Joystream Health LLC', location: 'Phoenix, AZ' },
             { id: 7, testimonial: 'Well you were certainly right. I do have a lot of energy now after your treatment and I am not sure what to do with all of it.', signature: 'Henrietta Flores', location: 'Keene, NH' }
         ]
       })*/;
});