define('life-light-web/components/application-view', ['exports', 'ember', 'ember-resize/mixins/resize-aware', 'life-light-web/mixins/scrolling'], function (exports, _ember, _emberResizeMixinsResizeAware, _lifeLightWebMixinsScrolling) {
    exports['default'] = _ember['default'].Component.extend(_emberResizeMixinsResizeAware['default'], _lifeLightWebMixinsScrolling['default'], {
        initialize: (function () {
            var self = this;
            self.trigger("debouncedDidResize", 0, 0, null);

            var scrollingOptions = { context: self, name: "lifelight", debounce: 10 };
            self.bindScrolling(scrollingOptions);

            CKEDITOR.stylesSet.add("llc_styles", [
            // Block level
            { name: "Skill paragraph", element: "p", attributes: { "class": "skill" } }, { name: "Rosie pull quote", element: "p", attributes: { "class": "rc-pull-quote" } }]);

            self.$("#modal1").on("shown.bs.modal", function () {
                self.get("onOpenOptionsModal")();
            });

            self.get("readjustFooter")(self);
            // self.$("#wrap").css("min-height", self.$(window).height() - self.$(".footer-container").height() - 50);
        }).on("didInsertElement"),

        didInsertElement: function didInsertElement() {
            $('.navbar-nav.nav-topbar').on('click', 'li a', function () {
                $('.navbar-collapse-top').collapse('hide');
            });

            $('#payment_modal').on('shown.bs.modal', function (e) {
                $('#paymentAmount', this).focus();
            });
        },

        willRemoveElement: function willRemoveElement() {
            this.unbindScrolling("lifelight");
        },

        deviceSize: "",
        classNameBindings: ["deviceSize"],

        didResize: function didResize() /*width, height, evt*/{
            if (this.$(".search-graph:visible")) {
                this.$(".search-graph").hide();
            }
        },

        debouncedDidResize: function debouncedDidResize(width, height /*, event*/) {
            var self = this;
            // console.log(`Debounced Resize! ${width}x${height}`);

            this.get("adjustSidebarNavigation")(self);

            var deviceSize = "nothing";
            if (self.$(".device-lg:visible").length) {
                deviceSize = "device-lg";
            } else if (self.$(".device-md:visible").length) {
                deviceSize = "device-md";
            } else if (self.$(".device-sm:visible").length) {
                deviceSize = "device-sm";
            } else if (self.$(".device-xs:visible").length) {
                deviceSize = "device-xs";
            }

            //** ECMA6        $("body.ember-application").removeClass("w-device-lg w-device-md w-device-sm w-device-xs").addClass("w-%@".fmt(deviceSize));
            self.$().closest("body.ember-application").removeClass("w-device-lg w-device-md w-device-sm w-device-xs").addClass("w-" + deviceSize);

            self.$(".search-graph").hide();

            self.get("readjustFooter")(self);
        },

        scrolled: function scrolled() {
            this.get("adjustSidebarNavigation")(this);
            // this.get("toggleBackToTop")(this);
        },

        adjustSidebarNavigation: function adjustSidebarNavigation(context) {
            var top = context.$(window).scrollTop();

            var wh = context.$(window).height();
            var nh = context.$("ul.nav-sidebar.navbar-collapse").height();

            var isFixed = wh > nh + 108 + 20 + 30;
            context.$(".navbar-header.navbar-desktop img, ul.nav-sidebar.navbar-collapse").toggleClass("nav-fixed", isFixed);

            var opacity = top > 108 ? 0 : (108.0 - top) / 108;
            context.$(".navbar-header.navbar-desktop img:nth-child(1)").css("opacity", opacity);
            context.$(".navbar-header.navbar-desktop img:nth-child(2)").css("opacity", 1.0 - opacity);
        },

        toggleBackToTop: function toggleBackToTop(context) {
            // var window = context.$(window);
            var top = context.$().closest('body').scrollTop();
            context.$('li.back-to-top').toggle(top > window.innerHeight);

            // Ember.Logger.info("Top: %@ - Window height: %@".fmt(top, $(window).height()));
            _ember['default'].Logger.info('Top: ' + top + ' - Window height: ' + window.innerHeight);
        },

        readjustFooter: function readjustFooter(context) {
            context.$("#wrap").css("min-height", context.$(window).height() - context.$(".footer-container").height() - 50 - 5);
            // Ember.Logger.info("Window: " + context.$(window).height());
        },

        positionSearchGraph: function positionSearchGraph(context) {
            var searchGraph$ = context.$(".search-graph");
            if (searchGraph$.length) {
                var searchGraphParent$ = searchGraph$.parent();
                var right = searchGraphParent$.offset().left + parseInt(searchGraphParent$.css("width")) - 10;
                searchGraph$.css({ left: right, top: "148px" }).show();
            }
        }
    });
});