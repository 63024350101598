define('life-light-web/components/font-sizer', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		// classNames: ['font-sizer'],
		classNameBindings: ['isDragging'],
		// attributeBindings: ['aria-labelledby'],

		keyUp: function keyUp(event) {
			var fontSize, delta;
			var minimumFontSize = this.get('minimumFontSize');
			var maximumFontSize = this.get('maximumFontSize');

			switch (event.keyCode) {
				case 38:
					delta = -2;break;
				case 40:
					delta = 2;break;
				case 68:
					fontSize = this.get("defaultSize");
					this.set('fontSize', fontSize);
					delta = this.$('.slider-container').height() * fontSize / maximumFontSize - this.$('.slider span').height();
					this.$('.slider').css('top', delta + 'px');
					return;
				default:
					_ember['default'].Logger.info(event.keyCode);
					break;
			}

			fontSize = this.get('fontSize') + delta;

			if (fontSize <= maximumFontSize && fontSize >= minimumFontSize) {
				delta *= (this.$('.slider-container').height() - this.$('.slider span').height()) / (maximumFontSize - minimumFontSize);
				this.$('.slider').css('top', this.$('.slider').position().top + delta + 'px');
				this.set('fontSize', fontSize);
			}
		},

		didInsertElement: function didInsertElement() {
			var self = this;
			var length = self.$('.slider-container').height();

			var minimumFontSize = this.get('minimumFontSize');
			var maximumFontSize = this.get('maximumFontSize');
			var range = maximumFontSize - minimumFontSize;
			var position = (this.get('fontSize') - minimumFontSize) / range * (length - parseInt(self.$('.slider span').css('height')));
			// var position = (this.get('fontSize') - minimumFontSize) / range * (length - 16);

			this.$('.slider').css({ 'left': 0, 'top': position }).draggable({
				axis: 'y',
				containment: 'parent',
				scrollable: false,
				cursor: 'ns-resize',
				// handle: ".handle",	// Needs an element inside .slider to have a handle
				drag: function drag() /*event, ui*/{
					self.set('isDragging', true);
					var position = _ember['default'].$(this).position().top;
					var fontSize = position / (length - _ember['default'].$(this).height()) * range + minimumFontSize;
					fontSize = Math.round(fontSize / 2) * 2;
					self.set('fontSize', fontSize);
					// Ember.Logger.info(`Position: ${position}, Size: ${fontSize}`);
				},
				stop: function stop() /*event, ui*/{
					self.set('isDragging', false);
				}
			});

			$('.font-sizer').on('show.bs.dropdown', function () {
				self.focus();
			});

			return this.$().attr({ tabindex: 1 }), this.$().focus();
		}
	});
});