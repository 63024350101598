define('life-light-web/mixins/scrolling', ['exports', 'ember', 'life-light-web/mixins/scrolling-dom-methods'], function (exports, _ember, _lifeLightWebMixinsScrollingDomMethods) {
    exports['default'] = _ember['default'].Mixin.create({
        /**
          https://coderwall.com/p/tes2cw/binding-to-scroll-events-with-ember-js
            Begin watching for scroll events. By default they will be called at max every 100ms.
          call with {debounce: N} for a diff time
            @method bindScrolling
          */
        bindScrolling: function bindScrolling(opts) {
            opts = opts || { debounce: 100 };

            var self = this,
                onScrollMethod = function onScrollMethod() {
                // return Em.run.scheduleOnce('afterRender', self, 'scrolled');
                return self.scrolled();
            };

            if (opts.debounce) {
                var context = opts.context || { name: 'debounce' };
                onScrollMethod = function () {
                    return _ember['default'].run.debounce(context, self.scrolled, opts.debounce);
                };
            }

            _lifeLightWebMixinsScrollingDomMethods['default'].bindOnScroll(onScrollMethod, opts.name);
        },

        /**
          Stop watching for scroll events.
            @method unbindScrolling
          */
        unbindScrolling: function unbindScrolling(name) {
            _lifeLightWebMixinsScrollingDomMethods['default'].unbindOnScroll(name);
        }
    });
});