define("life-light-web/components/home-carousel", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        elementId: "carousel-lifelight",
        classNames: ["carousel", "slide", "vertical"],
        attributeBindings: ["dataRide:data-ride"],
        dataRide: "carousel",

        carousel_paused: false,
        didInsertElement: function didInsertElement() {
            var self = this;
            var element = self.$();

            var timer = _ember["default"].run.later(function () {
                // $("#carousel-lifelight")
                self.$().carousel({
                    interval: 7000
                }).carousel("cycle");
            }, 7000);
            self.set("timerInfo", timer);

            self.$(".carousel-inner").hover(function () {
                self.get("carousel_paused") || element.carousel("pause");
            }, function () {
                self.get("carousel_paused") || element.carousel("cycle");
            }).click(function () {
                element.carousel(self.get("carousel_paused") ? "cycle" : "pause");
                self.toggleProperty("carousel_paused");
                self.$(this).toggleClass("paused", self.get("carousel_paused"));
            });
        },

        willDestroyElement: function willDestroyElement() {
            _ember["default"].run.cancel(this.get("timerInfo"));
        }
    });
});