define('life-light-web/controllers/articles/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        articles: _ember['default'].inject.controller(),

        queryParams: ["selectedTag"],
        selectedTag: null,

        actions: {
            deleteTag: function deleteTag(tag) {
                // Ember.Logger.info(`Controller got deleteTag with id ${id}`);
                /*            this.get('articles.tagList').findBy('id', id.toString()).destroyRecord();
                            return;
                */
                this.target.send('deleteTag', tag);
                return true;
                /*    		var self = this;
                    		self.get('store').find('tag', id).then(function(tag) {
                    			tag.destroyRecord();
                    		});
                */
            },

            updateTag: function updateTag(id, name) {
                this.target.send('updateTag', id, name);
            }
        }
    });
});