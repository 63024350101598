define("life-light-web/components/glyphicon-checkbox", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        tagName: "span",
        options: {},
        defaults: {
            visibleLabels: "right" // left, right, both, none
        },
        classNameBindings: [":glyphicon-checkbox", "checked"],
        dataToggle: "tooltip",
        dataPlacement: "left",
        targetId: undefined,
        checked: true,
        iconName: "",
        uncheckedText: "label text",
        checkedText: "",

        labelText: (function () {
            return this.get("checked") && Boolean(this.get("checkedText")) ? this.get("checkedText") : this.get("uncheckedText");
        }).property("checked", "uncheckedText", "checkedText"),
        initialize: (function () {
            if (!!!this.get("glyph")) {
                throw "%@ must include the glyph parameter on instantiation.".fmt(this.toString());
            }
            var options = {};
            var visibleLabels = this.get("visibleLabels");
            if (visibleLabels) {
                options = _ember["default"].$.extend({}, this.get("defaults"), { visibleLabels: visibleLabels });
            }
            this.set("options", options);
        }).on("init"),

        click: function click() {
            this.toggleProperty("checked");
            this.get("onChecked")(this.get("checked"));
        },

        didInsertElement: function didInsertElement() {
            var self = this;
            self.$().css("cursor", "pointer").hover(function () {
                self.$("label").stop().show().animate({ opacity: 1.0 }, "slow");
            }, function () {
                self.$("label").animate({ opacity: 0 }, "slow", function () {
                    _ember["default"].$(this).hide();
                });
            }).children("span.glyphicon").css("top", "2px")
            // .addClass("glyphicon-%@".fmt(this.get("glyph")))
            .addClass("glyphicon-" + this.get("glyph")).siblings("label").css("cursor", "pointer").css({ opacity: 0, display: "none" });

            this.$("span.glyphicon").popover({
                content: function content() {
                    return self.get("labelText");
                }
            });

            var selector;
            switch (this.get("options").visibleLabels) {
                case "left":
                    selector = "label:first";
                    break;
                case "right":
                    selector = "label:last";
                    break;
                case "both":
                    selector = "label";
                    break;
                default:
            }

            if (selector) {
                self.$(selector).removeClass("hidden");
            }
        }
    });
});