define("life-light-web/templates/components/articles-index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 1
              },
              "end": {
                "line": 3,
                "column": 217
              }
            },
            "moduleName": "life-light-web/templates/components/articles-index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "article.title", ["loc", [null, [3, 200], [3, 217]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "life-light-web/templates/components/articles-index.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["article", ["get", "article", ["loc", [null, [3, 22], [3, 29]]]]], ["class", ["subexpr", "concat", ["btn btn-primary btn-article", " ", ["subexpr", "if", [["get", "article.btnType", []], ["subexpr", "-normalize-class", ["article.btnType", ["get", "article.btnType", []]], [], []]], [], []], " ", ["subexpr", "if", [["get", "article.searching", []], ["subexpr", "-normalize-class", ["article.searching", ["get", "article.searching", []]], [], []]], [], []], " ", ["subexpr", "if", [["get", "article.inactive", []], "btn-xs", "btn-sm"], [], []], " ", ["subexpr", "if", [["get", "article.searched", []], ["subexpr", "-normalize-class", ["article.searched", ["get", "article.searched", []]], ["activeClass", "", "inactiveClass", "btn-deemphasize"], []], "btn-deemphasize"], [], []], " "], [], []], "type", "button"], 0, null, ["loc", [null, [3, 1], [3, 229]]]]],
        locals: ["article"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 68
                  },
                  "end": {
                    "line": 15,
                    "column": 102
                  }
                },
                "moduleName": "life-light-web/templates/components/articles-index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Edit mode");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 102
                  },
                  "end": {
                    "line": 15,
                    "column": 119
                  }
                },
                "moduleName": "life-light-web/templates/components/articles-index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("List mode");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 16,
                  "column": 4
                }
              },
              "moduleName": "life-light-web/templates/components/articles-index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("					");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-link");
              var el2 = dom.createTextNode("Switch to ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(element4, 1, 1);
              return morphs;
            },
            statements: [["element", "action", ["toggleEdit"], [], ["loc", [null, [15, 34], [15, 57]]]], ["block", "unless", [["get", "isEditingTags", ["loc", [null, [15, 78], [15, 91]]]]], [], 0, 1, ["loc", [null, [15, 68], [15, 130]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 3
              },
              "end": {
                "line": 17,
                "column": 3
              }
            },
            "moduleName": "life-light-web/templates/components/articles-index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            dom.setAttribute(el1, "class", "tag-header");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "glyphicon glyphicon-tags");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Tags");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "glyphicon glyphicon-info-sign");
            dom.setAttribute(el1, "data-toggle", "tooltip");
            dom.setAttribute(el1, "title", "Tags enable you to search articles by keywords identified by the author. Selecting multiple tags searches for articles marked by all of the tags selected. Click on the Uncheck all tags button to clear your tag search.");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [14, 10], [14, 33]]]]], [], 0, null, ["loc", [null, [14, 4], [16, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 5
                },
                "end": {
                  "line": 39,
                  "column": 5
                }
              },
              "moduleName": "life-light-web/templates/components/articles-index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              var el2 = dom.createTextNode("\n							");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n						");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n						");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              var el2 = dom.createTextNode("\n							");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n						");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(fragment, [3]);
              var morphs = new Array(8);
              morphs[0] = dom.createAttrMorph(element2, 'class');
              morphs[1] = dom.createAttrMorph(element2, 'data-tagid');
              morphs[2] = dom.createElementMorph(element2);
              morphs[3] = dom.createMorphAt(element2, 1, 1);
              morphs[4] = dom.createAttrMorph(element3, 'class');
              morphs[5] = dom.createAttrMorph(element3, 'data-tagid');
              morphs[6] = dom.createElementMorph(element3);
              morphs[7] = dom.createMorphAt(element3, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["hide btn btn-xs btn-tag ", ["subexpr", "if", [["get", "tag.checked", ["loc", [null, [33, 50], [33, 61]]]], "btn-primary", "btn-default"], [], ["loc", [null, [33, 45], [33, 91]]]]]]], ["attribute", "data-tagid", ["get", "tag.model.id", ["loc", [null, [33, 133], [33, 145]]]]], ["element", "action", ["toggleTag", ["get", "tag", ["loc", [null, [33, 114], [33, 117]]]]], [], ["loc", [null, [33, 93], [33, 119]]]], ["content", "tag.model.text", ["loc", [null, [34, 7], [34, 25]]]], ["attribute", "class", ["concat", ["btn btn-link btn-tag ", ["subexpr", "if", [["get", "tag.checked", ["loc", [null, [36, 47], [36, 58]]]], "active"], [], ["loc", [null, [36, 42], [36, 69]]]]]]], ["attribute", "data-tagid", ["get", "tag.model.id", ["loc", [null, [36, 111], [36, 123]]]]], ["element", "action", ["toggleTag", ["get", "tag", ["loc", [null, [36, 92], [36, 95]]]]], [], ["loc", [null, [36, 71], [36, 97]]]], ["content", "tag.model.text", ["loc", [null, [37, 7], [37, 25]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 5
                },
                "end": {
                  "line": 42,
                  "column": 5
                }
              },
              "moduleName": "life-light-web/templates/components/articles-index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "tag-button-component", [], ["model", ["subexpr", "@mut", [["get", "tag.model", ["loc", [null, [41, 35], [41, 44]]]]], [], []], "onDeleteTag", ["subexpr", "action", ["updateTagName"], [], ["loc", [null, [41, 57], [41, 81]]]], "onUpdateTag", ["subexpr", "action", ["updateTagName"], [], ["loc", [null, [41, 94], [41, 118]]]]], ["loc", [null, [41, 6], [41, 120]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 4
              },
              "end": {
                "line": 43,
                "column": 4
              }
            },
            "moduleName": "life-light-web/templates/components/articles-index.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "isEditingTags", ["loc", [null, [31, 15], [31, 28]]]]], [], 0, 1, ["loc", [null, [31, 5], [42, 16]]]]],
          locals: ["tag", "index"],
          templates: [child0, child1]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 4
              },
              "end": {
                "line": 47,
                "column": 4
              }
            },
            "moduleName": "life-light-web/templates/components/articles-index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("					");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "glyphicon glyphicon-plus text-success btn-new-tag");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["addTag"], [], ["loc", [null, [46, 69], [46, 88]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 5
                },
                "end": {
                  "line": 52,
                  "column": 5
                }
              },
              "moduleName": "life-light-web/templates/components/articles-index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-link text-danger");
              var el2 = dom.createTextNode("Uncheck all tags");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'disabled');
              morphs[1] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [["attribute", "disabled", ["get", "noTagsChecked", ["loc", [null, [51, 84], [51, 97]]]]], ["element", "action", ["clearAllTags"], [], ["loc", [null, [51, 47], [51, 72]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 4
              },
              "end": {
                "line": 53,
                "column": 4
              }
            },
            "moduleName": "life-light-web/templates/components/articles-index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "isEditingTags", ["loc", [null, [50, 15], [50, 28]]]]], [], 0, null, ["loc", [null, [50, 5], [52, 16]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 4
              },
              "end": {
                "line": 54,
                "column": 96
              }
            },
            "moduleName": "life-light-web/templates/components/articles-index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h3");
            dom.setAttribute(el1, "class", "text-danger");
            var el2 = dom.createTextNode("No articles match your search criteria.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 86,
              "column": 0
            }
          },
          "moduleName": "life-light-web/templates/components/articles-index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "tags");
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-6");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("		");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-6");
          var el4 = dom.createTextNode("\n			");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h5");
          dom.setAttribute(el4, "class", "tag-visibility");
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "#");
          var el6 = dom.createTextNode("\n					");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" tags");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n			");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n		");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n	");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-12");
          var el4 = dom.createTextNode("\n			");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("				");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("input");
          dom.setAttribute(el5, "id", "tag_defocus");
          dom.setAttribute(el5, "style", "position:absolute;left:-1000px");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("				");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n			");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n		");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n	");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "confirm");
          dom.setAttribute(el1, "class", "modal fade");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "modal-body");
          var el3 = dom.createTextNode("\n    Are you sure?\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "modal-footer");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "type", "button");
          dom.setAttribute(el3, "data-dismiss", "modal");
          dom.setAttribute(el3, "class", "btn btn-primary");
          dom.setAttribute(el3, "id", "delete");
          var el4 = dom.createTextNode("Delete");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "type", "button");
          dom.setAttribute(el3, "data-dismiss", "modal");
          dom.setAttribute(el3, "class", "btn");
          var el4 = dom.createTextNode("Cancel");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "tag-delete-modal");
          dom.setAttribute(el1, "class", "modal fade");
          dom.setAttribute(el1, "tabindex", "-1");
          dom.setAttribute(el1, "role", "dialog");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "modal-dialog");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "modal-content");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "modal-header");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5, "type", "button");
          dom.setAttribute(el5, "class", "close");
          dom.setAttribute(el5, "data-dismiss", "modal");
          dom.setAttribute(el5, "aria-label", "Close");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "aria-hidden", "true");
          var el7 = dom.createTextNode("×");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("h4");
          dom.setAttribute(el5, "class", "modal-title");
          var el6 = dom.createTextNode("Delete Tag");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "modal-body");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createTextNode("Do you really want to delete the ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "tag-name");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" tag?");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "modal-footer");
          var el5 = dom.createTextNode("\n	    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5, "type", "button");
          dom.setAttribute(el5, "data-dismiss", "modal");
          dom.setAttribute(el5, "class", "btn btn-primary");
          dom.setAttribute(el5, "id", "delete");
          var el6 = dom.createTextNode("Yes, delete the ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "tag-name");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" tag");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n	    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5, "type", "button");
          dom.setAttribute(el5, "data-dismiss", "modal");
          dom.setAttribute(el5, "class", "btn");
          var el6 = dom.createTextNode("No");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" /.modal-content ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" /.modal-dialog ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" /.modal ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [0]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [3, 1, 1]);
          var element8 = dom.childAt(element7, [1]);
          var element9 = dom.childAt(element5, [3, 1, 1]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
          morphs[1] = dom.createElementMorph(element7);
          morphs[2] = dom.createAttrMorph(element8, 'class');
          morphs[3] = dom.createMorphAt(element7, 3, 3);
          morphs[4] = dom.createAttrMorph(element9, 'class');
          morphs[5] = dom.createMorphAt(element9, 1, 1);
          morphs[6] = dom.createMorphAt(element9, 5, 5);
          morphs[7] = dom.createMorphAt(element9, 7, 7);
          morphs[8] = dom.createMorphAt(element9, 9, 9);
          return morphs;
        },
        statements: [["block", "if", [["get", "showTags", ["loc", [null, [11, 9], [11, 17]]]]], [], 0, null, ["loc", [null, [11, 3], [17, 10]]]], ["element", "action", ["toggleTags"], [], ["loc", [null, [21, 16], [21, 39]]]], ["attribute", "class", ["concat", ["glyphicon glyphicon-tags ", ["subexpr", "if", [["get", "showTags", ["loc", [null, [22, 48], [22, 56]]]], "hide"], [], ["loc", [null, [22, 43], [22, 65]]]]]]], ["inline", "if", [["get", "showTags", ["loc", [null, [22, 80], [22, 88]]]], "Hide", "Show"], [], ["loc", [null, [22, 75], [22, 104]]]], ["attribute", "class", ["concat", ["tag-list ", ["subexpr", "unless", [["get", "showTags", ["loc", [null, [28, 33], [28, 41]]]], "hide"], [], ["loc", [null, [28, 24], [28, 50]]]]]]], ["block", "each", [["get", "checkedTags", ["loc", [null, [30, 12], [30, 23]]]]], [], 1, null, ["loc", [null, [30, 4], [43, 13]]]], ["block", "if", [["get", "isEditingTags", ["loc", [null, [45, 10], [45, 23]]]]], [], 2, null, ["loc", [null, [45, 4], [47, 11]]]], ["block", "if", [["get", "sortedTags", ["loc", [null, [49, 10], [49, 20]]]]], [], 3, null, ["loc", [null, [49, 4], [53, 11]]]], ["block", "if", [["get", "showTagNoResults", ["loc", [null, [54, 10], [54, 26]]]]], [], 4, null, ["loc", [null, [54, 4], [54, 103]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "life-light-web/templates/components/articles-index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "article-list");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["block", "each", [["get", "model", ["loc", [null, [2, 8], [2, 13]]]]], [], 0, null, ["loc", [null, [2, 0], [4, 9]]]], ["block", "if", [["get", "checkedTags", ["loc", [null, [7, 6], [7, 17]]]]], [], 1, null, ["loc", [null, [7, 0], [86, 7]]]], ["content", "yield", ["loc", [null, [88, 0], [88, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});