define('life-light-web/router', ['exports', 'ember', 'life-light-web/config/environment'], function (exports, _ember, _lifeLightWebConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _lifeLightWebConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('home', { path: "/" });
    this.route('services');
    this.route('energy');
    this.route('articles', { resetNamespace: true }, function () {
      this.route('article', { resetNamespace: true, path: '/:article_id' }, function () {
        this.route("edit");
      });
      this.route('create', { path: '/create' });
    });
    this.route('credentials');
    this.route('associates');
    this.route('testimonials');
    this.route('pressurepoint');
    this.route('sitemap');
    this.route('workshop');
    this.route('login');
    this.route('page-not-found', { path: '/*wildcard' });
    this.route('payments');
  });

  exports['default'] = Router;
});