define("life-light-web/components/publish-source-with-popover", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        tagName: "span",
        attributeBindings: ["role", "dataTrigger:data-trigger", "dataPlacement:data-placement", "tabIndex"],
        dataTrigger: "focus",
        dataPlacement: "bottom",
        role: "button",
        tabIndex: 0,

        classNames: ["publish-popover", "publish-text"],

        didInsertElement: function didInsertElement() {
            var self = this;

            self.$().popover({
                html: true,
                delay: { "show": 0, "hide": 500 },
                template: "<div class=\"popover\" role=\"tooltip\"><div class=\"arrow\"></div><div class=\"popover-title\"></div><div class=\"popover-content\"></div></div>",
                title: function title() {
                    var subType = self.get("subType");
                    return "<img class='' src='/assets/images/%@' width='244px' />".fmt(subType === "sentinel" ? "2013sslogo.png" : "Shopper-Web-Site.png");
                },
                content: function content() {
                    return self.get("subType") === "sentinel" ? "Rose is a regular contributor to the <a href='http://www.sentinelsource.com/' target='_sentinel'>Sentinel News</a>. These articles have been published in previous editions of the Sentinel." : "Rose is a regular contributor to the <a href='http://www.shoppernews.com/' target='_monadnock'>Monadnock Shopper News</a>. These articles have been published in previous editions of the Shopper.";
                }
            }).on("shown.bs.popover", function () {
                _ember["default"].$(document).on("keyup.popover", function (event) {
                    if (event.which === 27) {
                        self.$("a.publish-popover").popover("hide");
                    }
                });
            }).on("hidden.bs.popover", function () {
                _ember["default"].$(document).off("keyup.popover");
            }).on("show.bs.popover", function () {
                //                $('.popover').attr('data-title', "<strong class='uppercase'>%@ News</strong>".fmt((subType == 'sentinel') ? "Sentinel" : "Monadnock Shopper"));
            });
        }
    });
});