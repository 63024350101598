define('life-light-web/helpers/formatted-date', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
	exports['default'] = _ember['default'].Helper.extend({
		compute: function compute(params /*, hash*/) {
			var theDate = params[0];
			var format = params[1];
			if (!!!theDate) {
				return;
			}
			return (0, _moment['default'])(new Date(theDate)).format(format);
		}
	});
});