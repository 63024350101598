define("life-light-web/components/action-anchor", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend(_ember["default"].ViewTargetActionSupport, {
		tagName: "a",
		attributeBindings: ["href", "download"],
		// actions: {
		click: function click() /*event*/{
			var actionMethod = this.get("onAction");
			if (actionMethod) {
				actionMethod(this.get("key"));
			}
			this.triggerAction();
		}
		// }
	});
});