define("life-light-web/components/article-navigator", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		tagName: "span",
		classNames: ["navigator-button-group"],
		previousTitle: "Previous article",
		nextTitle: "Next article",

		actions: {
			onNavigate: function onNavigate(direction) {
				this.get("onNavigate")(direction);
			}
		}
	});
});