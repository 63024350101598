define("life-light-web/templates/components/article-navigator", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "life-light-web/templates/components/article-navigator.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "artikle-navigation");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "glyphicon glyphicon-chevron-left");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "artikle-navigation");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "glyphicon glyphicon-chevron-right");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'disabled');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createAttrMorph(element1, 'title');
        morphs[3] = dom.createAttrMorph(element2, 'disabled');
        morphs[4] = dom.createElementMorph(element2);
        morphs[5] = dom.createAttrMorph(element3, 'title');
        morphs[6] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["attribute", "disabled", ["get", "isFirstArticle", ["loc", [null, [1, 48], [1, 62]]]]], ["element", "action", ["onNavigate", "-1"], [], ["loc", [null, [1, 8], [1, 36]]]], ["attribute", "title", ["get", "previousTitle", ["loc", [null, [1, 147], [1, 160]]]]], ["attribute", "disabled", ["get", "isLastArticle", ["loc", [null, [2, 47], [2, 60]]]]], ["element", "action", ["onNavigate", "1"], [], ["loc", [null, [2, 8], [2, 35]]]], ["attribute", "title", ["get", "nextTitle", ["loc", [null, [2, 146], [2, 155]]]]], ["content", "yield", ["loc", [null, [3, 0], [3, 9]]]]],
      locals: [],
      templates: []
    };
  })());
});