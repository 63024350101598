define('life-light-web/components/article-editor', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
	exports['default'] = _ember['default'].Component.extend({
		articleTypes: [{ text: "Periodicals", value: "shopperNews" }, { text: "Other", value: "other" }],
		articleSubTypes: [{ text: "Shopper News", value: "shopperNews" }, { text: "Sentinel", value: "sentinel" }],

		actions: {
			onSubmit: function onSubmit() {
				var self = this;
				this.set("model.body", CKEDITOR.instances.articleBody.getData());
				this.set("model.publishDate", (0, _moment['default'])(new Date(this.get("formatted_publish_date"))).toDate());
				var formatted_activation_date = this.get("formatted_activation_date");
				this.set("model.activationDate", formatted_activation_date ? (0, _moment['default'])(new Date(formatted_activation_date)).toDate() : new Date());
				this.set("model.categories", this.get("categories").filterBy("checked").mapBy("name"));
				if (!!!this.get("model.articleId")) {
					this.set("model.articleId", this.get("suggestedArticleId"));
				}

				self.get("model").save().then(function () {
					self.get("onSubmit")();
				});
			},
			cancel: function cancel() {
				this.get("onCancel")();
			},
			changeType: function changeType() {
				this.get("onChangeType")(this.$("#articleType").val());
			},
			changeSubType: function changeSubType() {
				this.get("onChangeSubType")(this.$("#articleSubType").val());
			},
			addNewTag: function addNewTag() {
				this.get("addNewTag")(this.get("newTagName"));
			},

			tagChecked: function tagChecked(id /*, value*/) {
				_ember['default'].Logger.info("Tag checked");
				var tag = this.get("model.tags").findBy("id", id);

				if (tag) {
					this.get("model.tags").removeObject(tag);
				} else {
					tag = this.get("tagList").findBy("id", id);
					this.get("model.tags").pushObject(tag);
				}
			}
		},

		classNames: ["container-edit"],

		areTagsDirty: (function () {
			var originalTags = this.get("originalTags");
			var checkTags = this.get("model.tags").mapBy("id");
			var isSame = _ember['default'].$(originalTags).not(checkTags).length === 0 && _ember['default'].$(checkTags).not(originalTags).length === 0;

			return !isSame;
		}).property("model.tags", "model.tags.[]"),

		isClean: (function () {
			return !this.get("model.hasDirtyAttributes") && !this.get("areTagsDirty");
		}).property("model.hasDirtyAttributes", "model.tags.hasDirtyAttributes"),

		formatted_publish_date: (function () {
			return (0, _moment['default'])(this.get("model.publishDate")).format("LL");
		}).property("model.publishDate"),

		formatted_activation_date: (function () {
			var date = this.get("model.activationDate");
			return date ? (0, _moment['default'])(date).format("LL") : null;
		}).property("model.activationDate"),

		categoriesObserver: (function () {
			this.get("onCategoryChecked")(this.get("categories").filterBy("checked").mapBy("name"));
		}).observes("categories.@each.checked"),

		checkedTagListObserver: (function () {
			var newTagName = this.get("newTagName");

			if (newTagName) {
				this.set("newTagName", undefined);
			}
		}).observes("checkedTagList.[]"),

		suggestedArticleId: (function () {
			var id = "Article ID";
			var subType = this.get("model.subType");
			var date = this.get("model.publishDate");

			if (subType === "shopperNews") {
				id = (0, _moment['default'])(date).format("MMMYY").toLowerCase();
			} else if (subType === "sentinel") {
				id = "sentinel" + (0, _moment['default'])(date).format("YYYYMMDD");
			}

			return id;
		}).property("model.publishDate", "model.subType"),

		useCustomFooterObserver: (function () {
			CKEDITOR.instances['customFooter'].setReadOnly(!this.get("model.useCustomFooter"));
			// if (this.get("useCustomFooter")) {
			// 	Ember.run.later(function() {
			// 		if(CKEDITOR.instances.customFooter) {
			// 			CKEDITOR.instances.customFooter.destroy();
			// 		} else {
			// 			CKEDITOR.replace("customFooter").on('change', function(e) {
			// 				// if (!$('#customFooter').focus()) {
			// 					var thisHTML = e.editor.getData();
			// 				    // Convert HTML to text
			// 				    // var tempDiv = $('<div>').html(thisHTML);
			// 				    // var thisText = tempDiv.text();
			// 				    $('#customFooter').val(thisHTML).trigger("keyup");
			// 				// }
			// 			});
			// 		}
			// 	}, 200);
			// } else {
			// 	if(CKEDITOR.instances.customFooter)
			// 		CKEDITOR.instances.customFooter.destroy();
			// }
		}).observes("model.useCustomFooter"),

		didInsertElement: function didInsertElement() {
			var self = this;
			self.$("#articleId").on("focus", function () {
				if (!self.$(this).val()) {
					self.$(this).val(self.get("suggestedArticleId"));
					self.$(this).trigger("keyup");
				}
			});

			CKEDITOR.replace("articleBody", {
				stylesSet: "llc_styles"
			}).on('change', function (e) {
				var thisHTML = e.editor.getData();
				self.$('#articleBody').val(thisHTML).trigger("keyup");
			});

			CKEDITOR.replace("synopsis").on('change', function (e) {
				var thisHTML = e.editor.getData();
				self.$('#synopsis').val(thisHTML).trigger("keyup");
			});

			CKEDITOR.replace("customFooter").on('change', function (e) {
				var thisHTML = e.editor.getData();
				self.$('#customFooter').val(thisHTML).trigger("keyup");
			});

			_ember['default'].run.later(function () {
				CKEDITOR.instances['customFooter'].setReadOnly(!self.get("model.useCustomFooter"));
			}, 500);

			self.$("#publishDate, #activationDate").datetimepicker({
				// format: "YYYY/MM/DD",
				format: "LL",
				showTodayButton: true
			});

			self.$("#article-title").focus();
		}
	});
});