define('life-light-web/components/featured-home-article', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		layout: (function () {
			return _ember['default'].HTMLBars.compile(this.get('content.template'));
		}).property('content'),

		content: (function () {
			var synopsis = this.get("article.synopsis");
			if (synopsis) {
				var content,
				    arr = synopsis.split("[title]");
				if (arr.length != 1) {
					var prefix = arr[0],
					    postfix = arr[1];
					var titleLink = '{{#link-to "article" "' + this.get("article.id") + '"}}' + this.get("article.title") + '{{/link-to}}';
					content = (prefix ? prefix : "") + titleLink + (postfix ? postfix : "");
				} else {
					content = synopsis;
				}

				return { template: content };
			}

			return null;
		}).property("article.synopsis")
	});
});