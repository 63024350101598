define("life-light-web/routes/articles", ["exports", "ember"], function (exports, _ember) {
	// import jqCookie from '../mixins/jq-cookie';

	// export default Ember.Route.extend(jqCookie, {
	exports["default"] = _ember["default"].Route.extend({
		session: _ember["default"].inject.service(),

		setupController: function setupController(controller, model) {
			controller.set("model", model);

			this.store.findAll("category").then(function (items) {
				var categories = items.mapBy("name");
				controller.set("categoryList", categories);
			});

			this.store.findAll("tag").then(function (tags) {
				controller.set("tagList", tags);
			});

			/*		var bookmarkId = controller.get("article.bookmarkId");
   		this.set("bookmarkId", bookmarkId);
   		if(bookmarkId !== undefined) {
   			this.transitionTo("article", bookmarkId);
   		}
   */
			var bookmarkId;
			// if ((bookmarkId = controller.get("bookmarkId")) && !!!this.paramsFor('article').anchor) {
			if ((bookmarkId = controller.get("bookmarkId")) && !!!this.paramsFor('article').article_id) {
				this.transitionTo("article", bookmarkId);
			}
		},

		model: function model() /*params*/{
			return this.store.findAll("article");
		},

		actions: {
			deleteTag: function deleteTag(tag) {
				tag.destroyRecord();
				return;

				var self = this;
				var articlesController = this.controllerFor('articles');
				articlesController.get('tagList').findBy('id', id.toString()).destroyRecord().then(function () {
					self.store.findAll("tag").then(function (tags) {
						articlesController.set("tagList", tags);
					});
				});
				/*			this.get('store').find('tag', id).then(function(tag) {
    				tag.destroyRecord();
    			});
    */
			},

			updateTag: function updateTag(tag) {
				var self = this;
				var articlesController = this.controllerFor('articles');

				if (!!!tag) {
					this.store.createRecord('tag');
				} else if (!!!tag.get('text')) {
					this.trigger('systemMessage', 'Tag name is required.');
				} else {
					switch (tag.currentState.stateName) {
						case 'root.loaded.created.uncommitted':
						case 'root.loaded.updated.uncommitted':
							tag.save();
							break;
						default:
							tag.save();
					}
				}
			},

			oldUpdateTag: function oldUpdateTag(id, name) {
				var self = this;
				var articlesController = this.controllerFor('articles');

				if (!!!id && !!!name) {
					this.store.createRecord('tag');
				} else if (!!!id && !!name) {
					this.store.filter('tag', { text: name }, function (tag) {
						tag.save();
					});
				} else {
					this.store.findRecord('tag', id).then(function (tag) {
						tag.set('text', name);
						tag.save().then(function (tag) {
							self.store.findAll("tag").then(function (tags) {
								articlesController.set("tagList", tags);
							});
						});
					});

					_ember["default"].Logger.info("New name: " + name);
				}
			},

			displaySystemMessage: (function (message) {
				var controller = this.controllerFor("articles");
				controller.displaySystemMessage(message);
			}).on('displaySystemMessage')
		}
	});
});