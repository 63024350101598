define('life-light-web/controllers/home', ['exports', 'ember', 'life-light-web/config/environment', 'life-light-web/mixins/jq-cookie'], function (exports, _ember, _lifeLightWebConfigEnvironment, _lifeLightWebMixinsJqCookie) {
	exports['default'] = _ember['default'].Controller.extend(_lifeLightWebMixinsJqCookie['default'], {
		persistentProperties: ["latestViewedArticleId:latestViewedArticleId::7"],

		articleCount: "many",

		hasViewedLatestArticle: (function () {
			return this.get("latestViewedArticleId") == this.get("latestArticle.id");
		}).property("latestViewedArticleId", "latestArticle"),

		showArticlesStub: (function () {
			return this.get("hasViewedLatestArticle") || !this.get("latestArticle.synopsis");
		}).property("hasViewedLatestArticle", "latestArticle.synopsis"),

		gaamp3download: 0,

		actions: {
			userViewClick: function userViewClick(key) {
				var self = this;

				_ember['default'].Logger.info(key + " clicked in the controller");
				_ember['default'].$.getJSON(_lifeLightWebConfigEnvironment['default'].APP.API_HOST + '/api/userviews/' + key + '/increment', {}, function (data) {
					self.set(key, data.findBy("key", key).value);
				});
			},

			clearLatestViewedArticleId: function clearLatestViewedArticleId() {
				this.set("latestViewedArticleId", undefined);
				this.persist(this, "latestViewedArticleId", undefined);
			},

			loadLatestViewedArticle: function loadLatestViewedArticle() {
				this.get("loadPersistedValue")(this, "latestViewedArticleId");
			}
		}
	});
});