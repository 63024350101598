define('life-light-web/components/tag-button-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['editable-button'],
    classNameBindings: ['isDirty'],
    isDirty: (function () {
      return this.get('model.hasDirtyAttributes');
    }).property('model.hasDirtyAttributes'),
    keyUp: function keyUp(event) {
      switch (event.keyCode) {
        case 27:
          this.get('model').rollbackAttributes();
          break;
      }
      // this.$('input').css('width', ((this.get('model.text.length') + 1) * 8) + 'px');
      _ember['default'].Logger.info('Keypress ' + event.keyCode);
    },
    actions: {
      deleteTag: function deleteTag() {
        this.get('onDeleteTag')(this.get('model'));
      },
      updateTag: function updateTag() {
        // this.get('onUpdateTag')(this.get('model.id'), this.get('model.text'));
        this.get('onUpdateTag')(this.get('model'));
        _ember['default'].$('#blank_focus').focus();
      },
      inputKeyUp: function inputKeyUp(event) {
        switch (event.keycode) {
          case 13:
            this.get('onUpdateTag')(this.get('model.id'), this.get('model.name'));
            break;
        }
      }
    },
    tagNameObserver: (function () {
      this.get('updateInputWidth')(this);
    }).observes('model.text'),
    didInsertElement: function didInsertElement() {
      this.get('updateInputWidth')(this);
    },
    updateInputWidth: function updateInputWidth(context) {
      context.$('input').css('width', (context.get('model.text.length') + 1) * 8 + 'px');
    }
  });
});