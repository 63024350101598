define('life-light-web/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      authenticateWithFacebookOpen: function authenticateWithFacebookOpen() {
        var controller = this.controllerFor('periodicals');
        // The provider name is passed to `open`
        this.get('torii').open('facebook-connect').then(function (authorization) {
          // FB.api is now available. authorization contains the UID and
          // accessToken.
          controller.set('hasFacebook', true);
        });
      }
    }
  });
});