define('life-light-web/components/search-graph', ['exports', 'ember', 'ember-resize/mixins/resize-aware'], function (exports, _ember, _emberResizeMixinsResizeAware) {
	exports['default'] = _ember['default'].Component.extend(_emberResizeMixinsResizeAware['default'], {
		classNames: ["search-graph", "search-graph-component"],
		classNameBindings: ["hasContent:active"],

		height: undefined,
		text: null,
		searchTerm: "null",
		textcontainer: ".article-body",
		searchIndexes: [],

		toddler: true,
		isVisible: _ember['default'].computed.alias("toddler"),

		hasContent: (function () {
			return this.get("searchIndexes.length") > 1;
		}).property("searchIndexes"),

		debouncedDidResize: function debouncedDidResize() /*width, height, evt*/{
			if (!!!this.get("height")) {
				this.$().height(_ember['default'].$(window).height() - 108 - 30 - 30 - 10 - 10);
			}
		},

		articleObserver: (function () {
			var self = this;
			var text = self.get("text");
			var searchTerm = self.get("searchTerm");
			if (!!!text) {
				return;
			}

			var idx = 0,
			    positions = [0],
			    total = text.length;
			do {
				idx = text.indexOf("<mark>", idx + 1);
				if (idx !== -1) {
					positions.push(idx);
				}
			} while (idx !== -1);

			positions = positions.map(function (pos) {
				return pos / total;
			});
			self.set("searchIndexes", positions);
			// App.USER_LOGGER_ENABLED && Em.Logger.log(positions);

			if (positions.length - 1) {
				self.$().empty().animate({ 'opacity': 1.0 });

				var height = self.$().height();

				positions.forEach(function (item, index /*, array*/) {
					var dash = _ember['default'].$("<div>");
					// dash.css("top", item * height).attr("id", "search%@".fmt(index)).attr("title", "Scroll to instance %@ of %@".fmt(index, searchTerm));
					dash.css("top", item * height).attr("id", 'search' + index).attr("title", 'Scroll to instance ' + index + ' of ' + searchTerm);
					self.$().append(dash);
				});
				self.$("#search0").attr("title", "Scroll to top of page");
			} else {
				self.$().empty().animate({ 'opacity': 0 });
			}
		}).observes("text"),

		didInsertElement: function didInsertElement() {
			var self = this;

			var height = self.get("height");
			if (!!!height) {
				self.trigger("debouncedDidResize");
			} else {
				self.$().height(height);
			}

			var parent = this.$().parent();
			var right = parent.offset().left + parseInt(parent.css("width")) - 10;
			this.$().css({ left: right, top: "148px" }).show();

			this.$().on("click", "div", function (e) {
				var top = 0;
				var id = e.target.id;
				var idx = parseInt(id.substr(6)) - 1;
				if (idx !== -1) {
					// top = $(".article-body mark").eq(idx).offset().top;
					top = _ember['default'].$("mark", self.get("textcontainer")).eq(idx).offset().top;
				}

				_ember['default'].$("html, body").animate({
					scrollTop: top
				}, 500);
			});
		}
	});
});