define('life-light-web/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
        setupController: function setupController(controller, model) {
            controller.set("model", model);

            this.store.findAll("userview").then(function (views) {
                controller.set("userviews", views);
            }, function (message) {
                _ember['default'].Logger.info("Data error: " + message);
            });

            controller.set("count", -1);
        },

        actions: {
            didTransition: function didTransition() {
                this.incrementProperty("controller.count");
            },

            showModal: function showModal(name, model) {
                this.render(name, {
                    into: "application",
                    outlet: "modal",
                    model: model
                });
            },

            removeModal: function removeModal() {
                this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            }
        },

        events: {
            goToLink: function goToLink(item, anchor) {
                var elem$ = _ember['default'].$(anchor);
                var scrollTo$ = _ember['default'].$('body').scrollTop(elem$.offset().top);

                this.transitionToRoute(item.route).then(scrollTo$); //.transitionTo is depricated
            }
        },

        sessionInvalidated: function sessionInvalidated() {
            var currentRoute = this.controllerFor('application').get('currentRouteName');

            if (currentRoute === 'article.index') {
                window.location.reload();
            } else {
                this._super();
            }
        }
    });
});