define('life-light-web/routes/home', ['exports', 'ember', 'life-light-web/config/environment'], function (exports, _ember, _lifeLightWebConfigEnvironment) {
	exports['default'] = _ember['default'].Route.extend({
		setupController: function setupController(controller /*, model*/) {
			controller.send("loadLatestViewedArticle");

			_ember['default'].$.getJSON(_lifeLightWebConfigEnvironment['default'].APP.API_HOST + '/api/articles/getLength', {}, function (data) {
				controller.set("articleCount", data);
			});

			this.store.findAll("article").then(function (items) {
				controller.set("latestArticle", items.get("lastObject"));
			});

			this.store.findAll("userview").then(function (views) {
				views.forEach(function (view) {
					var key = view.get("key");
					if (key) {
						controller.set(key, view.get("value"));
					}
				});
			});
		}
	});
});