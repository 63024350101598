define('life-light-web/controllers/articles/create', ['exports', 'ember', 'moment', 'life-light-web/mixins/jq-cookie'], function (exports, _ember, _moment, _lifeLightWebMixinsJqCookie) {
    exports['default'] = _ember['default'].Controller.extend(_lifeLightWebMixinsJqCookie['default'], {
        session: _ember['default'].inject.service('session'),
        searchManager: _ember['default'].inject.service("search-manager"),

        articles: _ember['default'].inject.controller(),
        persistentProperties: ["fontSize:articleFontSize::365", "bookmarkId:articleBookmark::365", "customerRatings:customerRatings::365"],

        isEditing: false,
        useCustomFooter: false,
        useCustomFooterObserver: (function () {
            this.set("useCustomFooter", this.get("model.useCustomFooter") ? true : false);
        }).observes("model"),

        fontSize: 16,
        bookmarkId: undefined,
        isBookmarked: (function () {
            return this.get("bookmarkId") === this.get("model.articleId");
        }).property("bookmarkId", "model.articleId"),

        isShopperNews: (function () {
            return this.get("model.type") === "shopperNews";
        }).property("model.type"),

        formatted_publish_date: (function () {
            // return moment.parse(Date(this.get("model.publishDate"))).format("LL");
            return (0, _moment['default'])(Date(this.get("model.publishDate"))).format("LL");
        }).property("model.publishDate"),

        categories: (function () {
            var self = this;
            var categoryList = self.get("articles.categoryList");
            var categories = categoryList.map(function (category) {
                var theCategories = self.get("model.categories");
                var checked = theCategories ? theCategories.indexOf(category) !== -1 : false;
                return { "name": category, "checked": checked };
            });

            return categories;
        }).property("model.categories", "articles.categoryList"),

        checkedTagList: (function () {
            var self = this;
            var tagList = self.get("articles.tagList");
            var tags = tagList.map(function (tag) {
                var articleTags = self.get("model.tags");
                var checked = articleTags.findBy("text", tag.get("text")) ? true : false;
                return { "id": tag.get("id"), "text": tag.get("text"), "checked": checked };
            });

            return tags;
        }).property("model.tags", "articles.tagList", "articles.tagList.[]"),

        actions: {
            toggleEditing: function toggleEditing() {
                this.toggleProperty("isEditing");
            },
            update: function update() {
                var self = this;
                self.get("model").save().then(function () {
                    self.set("isEditing", false);
                    self.store.findAll("article");
                    self.transitionToRoute("article", self.get("model.id"));
                    // self.get("articles").reload();
                });
            },
            cancel: function cancel() {
                if (this.get("model.id")) {
                    this.get("model").rollbackAttributes();
                } else {
                    this.get("model").deleteRecord();
                }

                this.set("isEditing", false);
                this.transitionToRoute("articles");
            },
            navigate: function navigate(direction) {
                this.get("articles").send("navigate", direction);
                // Ember.Logger.info("Navigate %@".fmt(direction));
            },
            searchChanged: function searchChanged(searchTerm) {
                //** ECMA6            Ember.Logger.info("Periodical search is: %@".fmt(searchTerm));
                _ember['default'].Logger.info("Periodical search is: " + searchTerm);
                this.set("searchTerm", searchTerm ? searchTerm : null);
                /*            if(!!!searchTerm) {
                                this.get("clearHighlights")(this);
                            } else {
                */ ////this.get("searchAndHighlight")(this);
                // }

                this.get("articles").send("searchChanged", searchTerm);
            },
            fontSizeChanged: function fontSizeChanged(fontSize) {
                this.set("fontSize", fontSize);
                // Ember.Logger.info("New font size: %@".fmt(this.get("fontSize")));
            },
            bookmarked: function bookmarked(marked) {
                // Ember.Logger.info("Bookmarked in Periodical with %@".fmt(marked));
                var bookmarkId = marked ? this.get("model.articleId") : undefined;
                this.set("bookmarkId", bookmarkId);
                this.get("articles").send("bookmarked", bookmarkId);
            },

            articleRated: function articleRated(value) {
                // Ember.Logger.info("%@ has been rated %@ stars.".fmt(this.get("model.title"), value));
                // Need to write this cookie
                // this.set("rated", value);
                var ratedArray = this.get("ratedArray");
                ratedArray[this.get("model.id")] = value;
                var jsonString = JSON.stringify(ratedArray);
                var encodedString = btoa(jsonString);
                this.set("customerRatings", encodedString);
                // this.set("ratedArray", ratedArray);
                // this.get("ratedArray")[this.get("model.id")] = value;
                this.incrementProperty("model.ratingCount");
                this.incrementProperty("model.ratingTotal", value);
                this.get("model").save();
            },

            testSearchManager: function testSearchManager() {
                this.get("searchManager").testSearchTerm();
            },

            changeType: function changeType(type) {
                this.set("model.type", type);
            },

            changeSubType: function changeSubType(type) {
                this.set("model.subType", type);
            },

            categoryChecked: function categoryChecked(categories) {
                this.set("model.categories", categories);
            },

            tagChecked: function tagChecked(tags) {
                this.set("model.tags", tags);
            },

            createNew: function createNew() {
                /*            var article = this.store.createRecord("article", {
                	            title: "New Article",
                	            type: "shopperNews",
                	            subType: "shopperNews"
                            });
                            // this.transitionToRoute("article", article);
                            this.set("model", article);
                */this.toggleProperty("isEditing");
            },

            remove: function remove() {
                this.get("model").destroyRecord();
                this.transitionToRoute("articles");
            },

            addNewTag: function addNewTag(tagName) {
                var self = this;
                var tag = this.store.createRecord("tag", {
                    text: tagName
                });
                tag.save().then(function (tag) {
                    self.get("model.tags").pushObject(tag);
                });
            }
        },

        customerRatings: "",
        ratedArray: (function () {
            var customerRatings = this.get("customerRatings");
            if (customerRatings) {
                customerRatings = atob(customerRatings);
                var ratedArray = JSON.parse(customerRatings);
                return ratedArray ? ratedArray : {};
            } else {
                return {};
            }
        }).property("customerRatings"),

        /*    customerRatingsObserver: function() {
                var customerRatings = this.get("customerRatings");
                var ratedArray = JSON.parse(customerRatings);
                this.set("ratedArray", ratedArray ? ratedArray : {});
            }.observes("customerRatings"),
        */
        rating: (function () {
            var total = this.get("model.ratingTotal");
            if (total) {
                return parseFloat(total / this.get("model.ratingCount"));
            } else {
                return null;
            }
        }).property("model.ratingTotal", "model.ratingCount"),

        modelObserver: (function () {
            var ratedArray = this.get("ratedArray");
            var id = this.get("model.id");
            var rating = ratedArray[id];
            this.set("rated", rating);
        }).observes("model"),

        // searchTerm: "null",
        searchTermObserver: (function () {
            this.get("searchAndHighlight")(this);
        }).observes("searchTerm", "model.body"),

        searchTarget: "body",
        searchAndHighlight: function searchAndHighlight(context) {
            var self = context;
            var srcStr = self.get("clearHighlights")(context);
            if (!!!srcStr) {
                return;
            }

            var term = self.get("searchTerm");
            if (term && term.length > 2 && self.get("searchTarget") === "body") {
                term = term.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
                var pattern = new RegExp("(" + term + ")", "gi");

                srcStr = srcStr.replace(pattern, "<mark>$1</mark>");
                srcStr = srcStr.replace(/(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, "$1</mark>$2<mark>$4");
            } else {
                _ember['default'].$(".search-graph").empty().animate({ 'opacity': 0 });
            }

            self.set("model.body", srcStr);
        },

        /*    queryParams: [{
                searchTarget: {
                    scope: "controller",
                    as: "searchtarget"
                },
                searchTerm: {
                    scope: "controller",
                    as: "search"
                }
            }],
        */
        clearHighlights: function clearHighlights(context) {
            var source = context.get("model.body");
            if (!!!source) {
                return null;
            }

            source = source.replace(/(<mark[^<>]*>|<\/mark>)/g, "");
            return source;
        }
    });
});