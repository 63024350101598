define("life-light-web/templates/components/testimonial-component", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes"]
          },
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "life-light-web/templates/components/testimonial-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          var el2 = dom.createElement("i");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" \n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "btn btn-xs btn-danger btn-remove");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "glyphicon glyphicon-trash");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Remove");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [0]);
          var element2 = dom.childAt(fragment, [2]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createAttrMorph(element1, 'class');
          morphs[3] = dom.createMorphAt(element0, 2, 2);
          morphs[4] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["btn btn-xs ", ["subexpr", "if", [["get", "testimonial.isApproved", ["loc", [null, [2, 78], [2, 100]]]], "btn-warning", "btn-success"], [], ["loc", [null, [2, 73], [2, 130]]]], " btn-approve"]]], ["element", "action", ["approve", ["get", "testimonial", ["loc", [null, [2, 41], [2, 52]]]]], [], ["loc", [null, [2, 22], [2, 54]]]], ["attribute", "class", ["concat", ["glyphicon ", ["subexpr", "if", [["get", "testimonial.isApproved", ["loc", [null, [2, 169], [2, 191]]]], "glyphicon-remove", "glyphicon-ok"], [], ["loc", [null, [2, 164], [2, 227]]]]]]], ["inline", "if", [["get", "testimonial.isApproved", ["loc", [null, [2, 239], [2, 261]]]], "Revoke", "Approve"], [], ["loc", [null, [2, 234], [2, 282]]]], ["element", "action", ["delete", ["get", "testimonial", ["loc", [null, [3, 40], [3, 51]]]]], [], ["loc", [null, [3, 22], [3, 53]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 90
            }
          },
          "moduleName": "life-light-web/templates/components/testimonial-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bona-fida");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["content", "testimonial.bonaFida", ["loc", [null, [9, 54], [9, 78]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "life-light-web/templates/components/testimonial-component.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        dom.setAttribute(el1, "class", "text");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "testimonee");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "signature");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "location");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [1]);
        var element4 = dom.childAt(element3, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createUnsafeMorphAt(element3, 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element4, 4, 4);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [6]), 0, 0);
        morphs[5] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "isAuthenticated", ["loc", [null, [1, 6], [1, 21]]]]], [], 0, null, ["loc", [null, [1, 0], [5, 7]]]], ["content", "testimonial.testimonial", ["loc", [null, [6, 16], [6, 45]]]], ["content", "testimonial.signature", ["loc", [null, [8, 26], [8, 51]]]], ["block", "if", [["get", "testimonial.bonaFida", ["loc", [null, [9, 8], [9, 28]]]]], [], 1, null, ["loc", [null, [9, 2], [9, 97]]]], ["content", "testimonial.location", ["loc", [null, [10, 25], [10, 49]]]], ["content", "yield", ["loc", [null, [14, 0], [14, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});