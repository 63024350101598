define('life-light-web/mixins/jq-cookie', ['exports', 'ember'], function (exports, _ember) {
	var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

	exports['default'] = _ember['default'].Mixin.create({
		persistentProperties: [],

		ginitialize: (function () {
			var self = this;
			self.get("persistentProperties").forEach(function (prop) {
				var _prop$split = prop.split(':');

				var _prop$split2 = _slicedToArray(_prop$split, 4);

				var propName = _prop$split2[0];
				var cookieName = _prop$split2[1];
				var propType = _prop$split2[2];
				var expires = _prop$split2[3];

				cookieName = typeof cookieName !== "undefined" ? cookieName : propName;

				var cookieValue, numeric, json, bool;

				if (cookieValue = Cookies.get(cookieName)) {
					if ((numeric = parseFloat(cookieValue)) == cookieValue) {
						cookieValue = numeric;
					} else if (bool = cookieValue === "true") {
						cookieValue = true;
					} else if (bool = cookieValue === "false") {
						cookieValue = false;
					} else if ((numeric = parseInt(cookieValue)) == cookieValue) {
						cookieValue = numeric;
						// } else if (typeof(json = JSON.parse(cookieValue)) === "object") {
						// 	cookieValue = json;
					}

					self.set(propName, cookieValue);
				}
				_ember['default'].addObserver(self, propName, self, self.get("persist"));
			});
		}).on("init"),

		willDestroyElement: function willDestroyElement() {
			var self = this;
			self._super();
			self.get("persistentProperties").forEach(function (prop) {
				_ember['default'].removeObserver(self, prop, self, self.get("persist"));
			});
		},

		loadPersistedValue: function loadPersistedValue(context, name) {
			context.get("persistentProperties").find(function (prop) {
				var _prop$split3 = prop.split(':');

				var _prop$split32 = _slicedToArray(_prop$split3, 4);

				var propName = _prop$split32[0];
				var cookieName = _prop$split32[1];
				var propType = _prop$split32[2];
				var expires = _prop$split32[3];

				if (propName == name) {
					var value = Cookies.get(cookieName);
					context.set(propName, value);
					return true;
				}

				return false;
			});
		},

		persist: function persist(context, propName, propType, expires) {
			var val = context.get(propName);
			var prop = context.get("persistentProperties").find(function (item /*, index, enumerable*/) {
				return item.split(":")[0] === propName;
			});

			prop = prop.split(":");

			if (typeof val === "object") {
				val = JSON.stringify(val);
			}

			// Ember.Logger.info(`Property: ${propName} has value ${val}`);

			var cookieName = typeof prop[1] !== "undefined" ? prop[1] : propName;
			// Cookies.set(propName, val);
			var options = prop[3] ? { expires: parseInt(prop[3]) } : {};
			if (val !== undefined) {
				Cookies.set(cookieName, val, options);
			} else {
				Cookies.remove(cookieName, options);
			}
		},

		persistAll: function persistAll() {
			var self = this;
			self.get("persistentProperties").forEach(function (prop) {
				_ember['default'].Logger.info("Property: %@".fmt(prop));
			});
		}
	});
});