define("life-light-web/templates/articles/create", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "life-light-web/templates/articles/create.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "article-edit");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["inline", "article-editor", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 23], [2, 28]]]]], [], []], "categories", ["subexpr", "@mut", [["get", "categories", ["loc", [null, [2, 40], [2, 50]]]]], [], []], "tagList", ["subexpr", "@mut", [["get", "articles.tagList", ["loc", [null, [2, 59], [2, 75]]]]], [], []], "checkedTagList", ["subexpr", "@mut", [["get", "checkedTagList", ["loc", [null, [2, 91], [2, 105]]]]], [], []], "originalTags", ["subexpr", "@mut", [["get", "originalTags", ["loc", [null, [2, 119], [2, 131]]]]], [], []], "onSubmit", ["subexpr", "action", ["update"], [], ["loc", [null, [2, 141], [2, 158]]]], "onCancel", ["subexpr", "action", ["cancel"], [], ["loc", [null, [2, 168], [2, 185]]]], "onChangeType", ["subexpr", "action", ["changeType"], [], ["loc", [null, [2, 199], [2, 220]]]], "onChangeSubType", ["subexpr", "action", ["changeSubType"], [], ["loc", [null, [2, 237], [2, 261]]]], "onCategoryChecked", ["subexpr", "action", ["categoryChecked"], [], ["loc", [null, [2, 280], [2, 306]]]], "onTagChecked", ["subexpr", "action", ["tagChecked"], [], ["loc", [null, [2, 320], [2, 341]]]], "addNewTag", ["subexpr", "action", ["addNewTag"], [], ["loc", [null, [2, 352], [2, 372]]]]], ["loc", [null, [2, 0], [2, 375]]]]],
      locals: [],
      templates: []
    };
  })());
});